import styles from './MumsMatterLoginWrapper.module.scss';

interface MumsMatterLoginWrapperProps {
  children?: any;
}

const MumsMatterLoginWrapper = ({ children }: MumsMatterLoginWrapperProps) => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
  </div>
);

export default MumsMatterLoginWrapper;
