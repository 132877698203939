import Radio from 'components/Radio/Radio';
import styles from './AddGPReferralForm.module.scss';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { ChangeEvent, useState } from 'react';
import { checkReferralFileSize, getDownloadFileName } from 'utils/uploadFile';
import { notification } from 'antd';
import FilePreview from 'SomeoneHealth/components/FilePreview/FilePreview';
import classNames from 'classnames';

export const PROVIDE_REFERRAL_DOCUMENTS = [
  { label: 'I have them ready to upload', value: 'client' },
  { label: 'My GP has sent them to you', value: 'gp' }
];

export interface UploadFile {
  bucketName: string;
  fileName: string;
  fileUrl: string;
}

export interface GPReferral {
  name?: string;
  practiceName?: string;
  medicareProviderNumber?: string;
  date?: string;
  files: File[];
  treatmentPlanFile?: File;
}

interface AddGPReferralFormProps {
  gpReferralValue: GPReferral;
  provideReferralDocuments: string;
  files?: UploadFile[];
  treatmentPlanFiles?: UploadFile[];
  setProvideReferralDocuments: (value: string) => void;
  setGPReferralValue: (value: GPReferral) => void;
  token: string;
  className?: string;
  classNameUploadArea?: string;
  callText?: string;
}

const AddGPReferralForm = ({
  gpReferralValue,
  provideReferralDocuments,
  setGPReferralValue,
  setProvideReferralDocuments,
  files,
  treatmentPlanFiles,
  token,
  className,
  classNameUploadArea,
  callText = 'your'
}: AddGPReferralFormProps) => {
  const [selectedFile, setSelectedFile] = useState<UploadFile | undefined>(undefined);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const handleUploadReferral = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const validatedFiles: File[] = [];
      files
        .filter(({ name }) => !gpReferralValue.files.map((item) => item.name).includes(name))
        .forEach((file) => {
          const fileSizeValidate = checkReferralFileSize(file);
          if (!fileSizeValidate.valid) {
            notification.error({ message: fileSizeValidate.message });
          } else {
            validatedFiles.push(file);
          }
        });
      setGPReferralValue({
        ...gpReferralValue,
        files: [...gpReferralValue.files, ...validatedFiles]
      });
    }
    event.target.value = '';
  };

  const renderFileName = (index: number, fileName: string, buttonLabel: string, buttonAction: () => void) => {
    return (
      <div key={index} className={styles.file}>
        <div className={styles.fileName}>
          <i className={'material-icons'}>attach_file</i>
          {getDownloadFileName(fileName)}
        </div>
        <ButtonSH variant={'text'} size={'small'} onClick={buttonAction}>
          {buttonLabel}
        </ButtonSH>
      </div>
    );
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.content}>
        {!files && (
          <div className={styles.fieldContainer}>
            <Radio
              vertical
              label={`How can you provide ${callText} referral documents to us?`}
              value={provideReferralDocuments}
              onChange={(event) => setProvideReferralDocuments(event.target.value)}
              options={PROVIDE_REFERRAL_DOCUMENTS}
            />
          </div>
        )}
        {provideReferralDocuments === 'client' && (
          <>
            {files && files.length > 0 && (
              <div className={styles.referralDocuments}>
                <div className={styles.label}>Referral Documents</div>
                {files.map((file, index) =>
                  renderFileName(index, file.fileName, 'View', () => {
                    setSelectedFile(file);
                    setIsPreviewModalVisible(true);
                  })
                )}
              </div>
            )}
            {treatmentPlanFiles && treatmentPlanFiles.length > 0 && (
              <div className={styles.treatmentPlans}>
                <div className={styles.label}>Treatment plan</div>
                {treatmentPlanFiles?.map((file, index) =>
                  renderFileName(index, file.fileName, 'View', () => {
                    setSelectedFile(file);
                    setIsPreviewModalVisible(true);
                  })
                )}
              </div>
            )}
            <div className={classNames(styles.uploadArea, classNameUploadArea)}>
              {/* Referral file */}
              <div>
                <div className={styles.uploadButtonContainer}>
                  <input
                    className={styles.uploadInput}
                    type={'file'}
                    id={'files'}
                    onChange={handleUploadReferral}
                    accept={'.pdf,.docx,image/png,image/jpeg'}
                    multiple
                  />
                  <label htmlFor={'files'}>
                    <div className={styles.uploadButton}>
                      <i className={`material-icons`}>upload</i>
                      <div className={styles.publishLabel}>
                        Upload your referral letter & Mental Health Treatment Plan
                      </div>
                    </div>
                  </label>
                </div>
                {gpReferralValue.files &&
                  gpReferralValue.files.map((file, index) =>
                    renderFileName(index, file.name, 'Remove', () =>
                      setGPReferralValue({
                        ...gpReferralValue,
                        files: gpReferralValue.files.filter((fil) => fil.name !== file.name)
                      })
                    )
                  )}
              </div>
            </div>
          </>
        )}
      </div>
      {token && selectedFile && (
        <FilePreview
          title={getDownloadFileName(selectedFile.fileName)}
          visible={isPreviewModalVisible}
          token={token}
          file={selectedFile}
          onCancel={() => {
            setIsPreviewModalVisible(false);
            setSelectedFile(undefined);
          }}
        />
      )}
    </div>
  );
};

export default AddGPReferralForm;
