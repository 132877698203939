import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { Clinician } from 'utils/hooks/clinician';

export const clinicianDetails = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountClinicianDetailsById: builder.query<
      PractitionerDetailsInterface,
      { slugUrl: string; clinicianId: string }
    >({
      query: ({ slugUrl, clinicianId }) => ({
        url: `/accounts/${slugUrl}/clinicians/${clinicianId}`
      }),
      providesTags: [CPSTagTypes.AttachedClinicianDetails]
    }),
    getClinicianDetailsById: builder.query<Clinician, { clinicianId: string }>({
      query: ({ clinicianId }) => ({
        url: `/clinician/${clinicianId}`
      }),
      providesTags: [CPSTagTypes.ClinicianDetails]
    })
  })
});

export const { useGetAccountClinicianDetailsByIdQuery, useGetClinicianDetailsByIdQuery } = clinicianDetails;
