import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { AppointmentBookingClaimType } from '../../interfaces/PublicProfile/Practitioner/practitioner';
import { PutPatientPayload } from 'utils/http/PatientProfileService/Patient/patient';

export interface ClientKeyContact {
  _id: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface MedicareStatus {
  code: number;
  message?: string;
}

export enum DvaCardType {
  Gold = 'gold',
  White = 'white',
  Orange = 'orange'
}

export interface MedicareInformation {
  number?: number;
  irn?: number;
  dva?: string;
  dvaCardDetails?: {
    type?: DvaCardType;
    expiryDate?: string;
  };
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  expiryDate?: string;
  status?: {
    medicare?: MedicareStatus;
    dva?: MedicareStatus;
  };
}

export interface ReferralDetailUpdateInterface {
  files: {
    bucketName: string;
    fileName: string;
    fileUrl: string;
  }[];
  treatmentPlanFile?: {
    bucketName: string;
    fileName: string;
    fileUrl: string;
  };
  isReferredByGP: boolean;
  isHaveTreatmentPlan: boolean;
  treatmentPlanDate?: string;
  name?: string;
  practiceName?: string;
  medicareProviderNumber?: string;
  generalPractitioner?: {
    name?: string;
    practiceName?: string;
    medicareProviderNumber?: string;
  };
  date?: string;
  isGPSentReferral?: boolean;
  addToReferralGroup?: boolean;
  firstName?: string;
  lastName?: string;
  faxNumber?: string;
}

export type ReferralDetail = Omit<ReferralDetailUpdateInterface, 'treatmentPlanFile' | 'treatmentPlanDate'> & {
  treatmentPlanFiles?: (Required<ReferralDetailUpdateInterface>['treatmentPlanFile'] &
    Pick<Required<ReferralDetailUpdateInterface>, 'treatmentPlanDate'>)[];
};

export interface PatientProfile {
  _id: string;
  recordType: ClientRecordType;
  avatar?: string;
  mobileNumber?: string;
  email?: string;
  name?: string;
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
  medicare?: MedicareInformation;
  keyClientContacts?: ClientKeyContact[];
  referral?: ReferralDetail;
  timeZone?: string;
  isAccountMedicareEnabled?: boolean;
  isAccountSmpEnabled?: boolean;
  isIp6Enabled?: boolean;
  isP2CGamblingEnabled?: boolean;
  hasSavedCard?: boolean;
  appointmentBookingClaimType?: AppointmentBookingClaimType;
  gpSurveyCompleted?: boolean;
  isReferralSentByGP?: boolean;
  appointmentQuota?: number;
  employerCode?: string;
  renewalDate?: string;
  showContentLibrary?: boolean;
  isEmergencyContactSetup?: boolean;
  address?: PutPatientPayload['address'];
  isRiskScreeningComplete?: boolean;
  accountId?: string;
  mumsMatterCustomisations?: { allowIntakeCallBooking: boolean };
}
