import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const initialCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  password: '',
  theme: '',
  communicationPreference: CommunicationPreference.NoPreference,
  hearAboutSource: ''
};

export const howDidYouHearAboutUsOptions = [
  { label: 'Online search ', value: 'Online search ' },
  { label: 'Friend or Family', value: 'Friend or Family' },
  { label: 'Insurance company', value: 'Insurance company' },
  { label: 'Social media', value: 'Social media' },
  { label: 'GP', value: 'GP' },
  { label: 'Private hospital', value: 'Private hospital' },
  { label: 'Other health professional ', value: 'Other health professional ' },
  { label: 'Other psychologist', value: 'Other psychologist' },
  { label: 'Employer', value: 'Employer' },
  { label: 'NHS', value: 'NHS' },
  { label: 'Used your service before', value: 'Used your service before' },
  { label: 'Other', value: 'Other' }
];

export const getUkMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+44' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const passwordValidationSchema = (pwScore: number) =>
  Yup.object().shape({
    password: Yup.string()
      .required('Please enter your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'This password is too weak', () => pwScore === 4)
      .nullable()
  });

export const formDataValidationSchema = () => {
  // const dobAge = 14;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    dateOfBirth: Yup.string().required('Please choose your date of birth'),
    // .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
    //   return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
    // }),
    hearAboutSource: Yup.string().required('Please select an option'),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    communicationPreference: Yup.string()
      .required('Please select a communication preference')
      .oneOf([CommunicationPreference.Email, CommunicationPreference.SMS, CommunicationPreference.NoPreference])
  });
};
