import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

export const PARTNER_CODE = [
  ...(isDevelopmentENV()
    ? [
        {
          accessToken: '686868',
          partnerCode: 'VTPHNA'
        },
        {
          accessToken: '575757',
          partnerCode: 'healthHub'
        }
      ]
    : []),
  {
    accessToken: 'TV46HN',
    partnerCode: 'VTPHNA'
  },
  {
    accessToken: 'XK92LP',
    partnerCode: 'healthHub'
  }
];
