import { clinicianProfileServicesApiSlice, CPSTagTypes } from '../../services/clinicianProfileServicesApiSlice';
import { MedicareInformation, PatientProfile } from '../../../components/UpdateProfileContent/interface';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import moment from 'moment';

interface SurveyItem {
  _id: string;
  createdAt: string;
  title: string;
  totalQuestions: number;
}

interface FailedPaymentItem {
  _id: string;
  amount: number;
  systemRetryCount: number;
  accountId: string;
  clientRecordId: string;
  status: string;
  type: string;
  method: string;
  appointmentId: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface TaskListInterface {
  consentList: { _id: string; name: string }[];
  onboardingQuestionCount: number;
  assessmentList: SurveyItem[];
  failedPayments: FailedPaymentItem[];
}

export interface ClientTagsPayload {
  label?: string;
}

export const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get client record details
    getClientDetails: builder.query<PatientProfile, null>({
      query: () => ({
        url: '/client-records/me'
      }),
      transformResponse: (response: PatientProfile) => {
        return {
          ...response,
          ...(response.medicare && {
            medicare: {
              ...response.medicare,
              dateOfBirth: moment(response.medicare?.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
          })
        };
      },
      providesTags: [CPSTagTypes.ClientRecordsMe]
    }),

    // Get attached clinician details
    getAttachedClinicianDetails: builder.query<GetAttachedClinicianDetails, null>({
      query: () => ({
        url: '/client-records/me:getAttachedAccountAndClinician'
      }),
      providesTags: [CPSTagTypes.AttachedClinicianDetails]
    }),

    // Get tasks
    getTasks: builder.query<TaskListInterface, { clientRecordId: string }>({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/tasks`
      }),
      transformResponse: (response: any) => {
        return {
          ...response,
          onboardingQuestionCount: response.onboarding,
          isTaskCompleted:
            !response.consent && response.assessmentList.length === 0 && response.failedPayments.length === 0
        } as TaskListInterface;
      },
      keepUnusedDataFor: 15,
      providesTags: [CPSTagTypes.ClientRecordTasks]
    }),

    putClientMedicare: builder.mutation<MedicareInformation, { body: MedicareInformation; authToken?: string }>({
      query: ({ body, authToken }) => {
        return {
          url: '/client-records/me/medicare',
          method: 'PUT',
          body,
          headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
        };
      },
      invalidatesTags: [CPSTagTypes.ClientMedicare]
    }),

    patchClientRecordsTags: builder.mutation<ClientTagsPayload, { body: ClientTagsPayload }>({
      query: ({ body }) => {
        return {
          url: '/client-records/me/tags',
          method: 'PATCH',
          body
        };
      },
      invalidatesTags: [CPSTagTypes.ClientRecordsTags]
    })
  })
});

export const {
  useGetClientDetailsQuery,
  useGetAttachedClinicianDetailsQuery,
  useGetTasksQuery,
  usePutClientMedicareMutation,
  usePatchClientRecordsTagsMutation
} = clientRecordsApiSlice;
