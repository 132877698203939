import { ReactNode, useState } from 'react';
import styles from './SelectCreateProfileForm.module.scss';
import { Formik } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import {
  formDataValidationSchema,
  howDidYouHearAboutUsOptions,
  initialCustomer,
  passwordValidationSchema
} from './constants';
import SelectMobileFormikInput from 'Select/components/SelectMobileFormikInput/SelectMobileFormikInput';
import FormikSelect from 'components/Select/FormikSelect';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { SelectPrivacyPolicyUrl, SelectTermsAndConditions } from 'Select/utils/env/SelectEnv';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { scrollErrorsIntoView } from 'utils/scrollToView';

interface SelectCreateProfileFormProps {
  isSubmitting: boolean;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialCustomer) => void;
  subHeader?: ReactNode;
}

const SelectCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  subHeader
}: SelectCreateProfileFormProps) => {
  const { loginWithRedirect } = useAuth0();
  const { LOGIN } = useSelectRoutesGenerator();

  const [pwScore, setPwScore] = useState(0);
  const [isPrivacyPolicyAgreeChecked, setIsPrivacyPolicyAgreeChecked] = useState(false);
  const [showPrivacyPolicyError, setShowPrivacyPolicyError] = useState(false);
  const [showPwError, setShowPwError] = useState(false);

  const handleValidateAndSubmit = (values: typeof initialCustomer) => {
    if (isPrivacyPolicyAgreeChecked) {
      onSubmit(values);
    }
  };

  const handleTogglePrivacyPolicyAgreeChecked = (value: boolean) => {
    setIsPrivacyPolicyAgreeChecked(value);
    setShowPrivacyPolicyError(true);
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'select-theme')}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            Welcome to Select Psychology
            <div className={styles.subHeader}>
              {subHeader ? subHeader : 'Create your secure account and take your first step to a better future.'}
            </div>
          </div>
        </div>
        <div className={styles.loginText}>
          Already have an account?{' '}
          <span
            onClick={() =>
              loginWithRedirect({
                loginType: 'patient',
                redirectUri:
                  (process.env.REACT_APP_CLIENT_DOMAIN_SELECT || 'https://my.selectpsychology.co.uk') + LOGIN,
                appState: { returnTo: window.location.pathname }
              })
            }
            className={styles.loginLink}
          >
            Login instead
          </span>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={passwordValidationSchema(pwScore).concat(formDataValidationSchema())}
          onSubmit={handleValidateAndSubmit}
        >
          {({ values, submitForm, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  id="firstName"
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  id="lastName"
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  id="dateOfBirth"
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                <FormikSelect
                  id="hearAboutSource"
                  className={styles.customSelect}
                  labelClass={styles.selectInputLabel}
                  label={'How did you hear about us?'}
                  placeholder={'Select'}
                  isSearchable={false}
                  name="hearAboutSource"
                  options={howDidYouHearAboutUsOptions}
                  styles={{
                    valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                  }}
                />
                <SelectMobileFormikInput
                  id={'mobileNumber'}
                  inputClass={styles.customInput}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="_____ ___ ___"
                  placeholder={'07712 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  id="email"
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  id="password"
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                  labelClass={styles.inputLabel}
                  allowShowPassword
                  showPasswordButtonClass={styles.showPasswordButtonClass}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.privacyPolicyCheckboxContainer}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="isPrivacyPolicyAgreeChecked"
                    value={isPrivacyPolicyAgreeChecked}
                    onChange={(e) => handleTogglePrivacyPolicyAgreeChecked(e.target.checked)}
                    className={styles.checkBox}
                    label={`I agree to Select Psychology's <a target="_blank" href="${SelectPrivacyPolicyUrl}">Privacy Policy</a> and <a target="_blank" href="${SelectTermsAndConditions}">Terms & Conditions</a>`}
                  />
                </div>
                <ErrorMessage
                  error="Please agree to the Privacy Policy and Terms & Conditions"
                  visible={showPrivacyPolicyError && !isPrivacyPolicyAgreeChecked}
                />
              </div>

              <ButtonSelect
                className={styles.submitButton}
                type="submit"
                onClick={() => {
                  setShowPwError(true);
                  setShowPrivacyPolicyError(true);
                  submitForm();
                  validateForm().then((errors) =>
                    scrollErrorsIntoView({ ...errors, isPrivacyPolicyAgreeChecked: !isPrivacyPolicyAgreeChecked })
                  );
                }}
              >
                Create Your Profile
              </ButtonSelect>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SelectCreateProfileForm;
