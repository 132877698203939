import { MouseEvent as ReactMouseEvent } from 'react';
import Button from 'components/v2/Button/Button';
import styles from './GatewayDone.module.scss';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import classnames from 'classnames';
import { IS_CAW_APP, IS_HELM_APP, IS_MUMS_MATTER_APP, IS_SELECT_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonMumsMatter from '../../../../../MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';

interface GatewayDoneProps {
  clinicianName?: string;
  totalQuestion?: number;
}

const GatewayDone = ({ clinicianName, totalQuestion }: GatewayDoneProps) => {
  const { token, isQueryParamToken } = useGetAccessToken(true);
  const { PSYCHOMETRIC } = useRoutesGenerator();
  const { isAuthenticated } = useAuth0();

  const handleHomeBtn = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    window.location.href = `${window.origin}${PSYCHOMETRIC.BASE}${isQueryParamToken ? `?token=${token}` : ''}`;
  };

  const labelByBrand =
    (IS_HELM_APP || IS_CAW_APP) && clinicianName ? (
      'Thank you for completing these questions.'
    ) : IS_SELECT_APP ? (
      'I look forward to meeting you at our appointment.'
    ) : (
      <>
        Thank you for completing the questions. This questionnaire has been submitted to <b>{clinicianName}</b> for
        review.
      </>
    );

  const titleByBrand =
    IS_HELM_APP || IS_CAW_APP
      ? ''
      : IS_SELECT_APP
      ? 'Thank you for taking the time to complete these questions.'
      : 'All done.';

  return (
    <div className={classnames(styles.container, IS_CAW_APP && 'caw-theme')}>
      <div className={styles.title}>{titleByBrand}</div>
      <div className={styles.introNote}>{labelByBrand}</div>
      <div className={styles.footerBtnCard}>
        <div className={styles.doneContent}>
          <div className={styles.successWrapper}>
            <span className={`material-icons ${styles.checkIcon}`}>done</span>
            <span className={styles.doneText}>
              {totalQuestion ? `All ${totalQuestion} questions answered.` : 'Psychometric submitted'}
            </span>
          </div>
          {(IS_HELM_APP && isAuthenticated) || !IS_HELM_APP ? (
            <div className={styles.homePageBtn}>
              {IS_MUMS_MATTER_APP ? (
                <ButtonMumsMatter onClick={handleHomeBtn}>Back to Task List</ButtonMumsMatter>
              ) : (
                <Button onClick={handleHomeBtn}>Back to Task List</Button>
              )}
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default GatewayDone;
