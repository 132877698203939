import { useAuth0 } from '@auth0/auth0-react';

import styles from './SurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';

const SurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Survey List`}>
      <MumsMatterHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <MumsMatterContentLayout className="mums-matter-theme">
          <SurveyList />
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default SurveyListWrapper;
