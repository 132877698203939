import styles from './MumsMatterHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LoginAvatar from 'MumsMatter/components/LoginAvatar/LoginAvatar';
import MumsMatterContentLayout from '../MumsMatterContentLayout/MumsMatterContentLayout';
import mumsMatterLogo from 'MumsMatter/assets/images/logo/mums_matter_full_logo.png';
import { MumsMatterSiteUrl } from 'MumsMatter/utils/env/MumsMatterEnv';

interface MumsMatterHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  className?: string;
  loginTextClass?: string;
  whiteMenu?: boolean;
  whiteBg?: boolean;
}

const MumsMatterHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  className,
  loginTextClass,
  whiteMenu,
  whiteBg
}: MumsMatterHeaderProps) => {
  const navigate = useNavigate();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = MumsMatterSiteUrl;
    }
  };

  return (
    <MumsMatterContentLayout
      className={classnames(
        noWrapper && styles.noWrapperContainer,
        withPadding && styles.padding,
        whiteBg && styles.whiteBg,
        className
      )}
    >
      <div id={'MumsMatterHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img className={styles.logo} alt={'logo'} src={mumsMatterLogo} />
        </div>
        <div className={styles.rightContent}>
          {!noLogin && (
            <LoginAvatar
              whiteFont={whiteFont}
              whiteMenu={whiteMenu}
              withMenu={withMenu}
              loginTextClass={loginTextClass}
            />
          )}
        </div>
      </div>
    </MumsMatterContentLayout>
  );
};

export default MumsMatterHeader;
