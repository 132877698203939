import { useAuth0 } from '@auth0/auth0-react';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import TaskBox from 'components/TaskBox/TaskBox';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import { useNavigate } from 'react-router-dom';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useFetchPsychometricList } from './hooks/getPsychometricList';

import styles from './PsychometricList.module.scss';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_MUMS_MATTER_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import SomeoneHealthHeader from '../../../SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import CaWHeader from '../../../CaW/components/CaWHeader/CaWHeader';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import classNames from 'classnames';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import { getTaskListTitleFromClinicianAccountSettings } from 'utils/general';
import PortobelloHeader from '../../../Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import MumsMatterHeader from '../../../MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';

const PsychometricList = () => {
  const { user, isAuthenticated } = useAuth0();
  const { token, userInfo } = useGetAccessToken(true);
  const navigate = useNavigate();

  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);

  const { HOME } = useRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? attachedClinicianDetail.clinician.name
    : attachedClinicianDetail?.practice?.name || '';

  const title = getTaskListTitleFromClinicianAccountSettings(attachedClinicianDetail);

  return (
    <>
      {IS_PORTOBELLO_APP && <PortobelloHeader withPadding withMenu homeLink />}
      {IS_MUMS_MATTER_APP && <MumsMatterHeader withPadding withMenu homeLink />}
      <PatientContentLayout
        className={classNames(
          IS_CAW_APP
            ? styles.cawContainer
            : IS_SOMEONE_HEALTH_APP
            ? `${styles.shContainer} s1h-client-portal-theme`
            : IS_EASE_APP
            ? styles.easeContainer
            : IS_RECHARGE_APP
            ? styles.rechargeContainer
            : IS_SELECT_APP
            ? styles.selectContainer
            : IS_PORTOBELLO_APP
            ? styles.portobelloContainer
            : IS_MUMS_MATTER_APP
            ? styles.mumsMatterContainer
            : styles.container,
          IS_EASE_APP && 'ease-theme',
          IS_RECHARGE_APP && 'recharge-theme',
          IS_SELECT_APP && 'select-theme',
          IS_MUMS_MATTER_APP && 'mums-matter-theme'
        )}
        style={
          !isBackgroundImageUrlLoading && backgroundImageUrl
            ? {
                backgroundImage: `url('${backgroundImageUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }
            : {}
        }
        contentClassName={
          IS_PORTOBELLO_APP ? styles.portobelloContentLayout : IS_MUMS_MATTER_APP ? styles.mumsMatterContentLayout : ''
        }
      >
        {IS_HELM_APP && (
          <div className={styles.headerWrapper}>
            <HelmHeader noWrapper whiteFont logoWithoutText whiteLogo withPadding withMenu homeLink />
          </div>
        )}
        {IS_SOMEONE_HEALTH_APP && (
          <div className={styles.headerWrapper}>
            <SomeoneHealthHeader withPadding withMenu homeLink />
          </div>
        )}
        {IS_CAW_APP && (
          <div className={styles.headerWrapper}>
            <CaWHeader withPadding withMenu homeLink blueMenu />
          </div>
        )}
        {IS_EASE_APP && (
          <div className={styles.headerWrapper}>
            <EaseHeader withPadding withMenu homeLink greenMenu />
          </div>
        )}
        {IS_RECHARGE_APP && (
          <div className={styles.headerWrapper}>
            <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
          </div>
        )}
        {IS_SELECT_APP && (
          <div className={styles.headerWrapper}>
            <SelectHeader withPadding withMenu homeLink whiteFont />
          </div>
        )}
        <div className={styles.contentWrapper}>
          {!IS_HELM_APP &&
            !IS_SOMEONE_HEALTH_APP &&
            !IS_CAW_APP &&
            !IS_EASE_APP &&
            !IS_RECHARGE_APP &&
            !IS_SELECT_APP &&
            !IS_PORTOBELLO_APP &&
            !IS_MUMS_MATTER_APP && <ClientHeader withMenu />}
          <div className={styles.content}>
            {isAttachedClinicianDetailLoading || isPsychometricListLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                {isAuthenticated && (
                  <div className={styles.backBtnWrapper}>
                    <div className={styles.backBtn} onClick={() => navigate(`/`)}>
                      <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                      Return to home page
                    </div>
                  </div>
                )}
                {psychometricList.length > 0 ? (
                  <div className={styles.taskListContainer}>
                    <div className={styles.taskListTitle}>
                      {title && <div className={styles.heading}>{title}</div>}
                      <strong>{clinicianName}</strong> has asked you to please complete the following{' '}
                      <strong>{psychometricList.length} actions</strong>
                      {userInfo.childFirstName && ` for ${userInfo.childFirstName}`}
                    </div>
                    <div className={styles.taskListBox}>
                      {psychometricList.map(({ _id, clinicalAssessmentIds, clinicianAuth0Id }, index) => (
                        <TaskBox
                          key={index}
                          title="Assessment"
                          desc={clinicalAssessmentIds.join(', ')}
                          onClick={() => navigate(`${HOME}/${clinicianAuth0Id}/assessment/${_id}?token=${token}`)}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.taskListContainer}>
                    <div className={styles.taskListTitle}>All psychometric assessment completed.</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </PatientContentLayout>
    </>
  );
};

export default PsychometricList;
