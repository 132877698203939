import styles from './AddPayment.module.scss';
import ButtonMumsMatter, { ButtonStatusType } from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import { IntakeAppointmentNoticePeriodInHour, IntakeCancellationFee } from 'MumsMatter/utils/env/MumsMatterEnv';

interface AddPaymentProps {
  name: string;
  buttonStatus: ButtonStatusType;
  onAddPaymentDetails: () => void;
}

const AddPayment = ({ name, buttonStatus, onAddPaymentDetails }: AddPaymentProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Add payment details</div>
      <div className={styles.content}>
        <b>Welcome {name}</b>
        <br />
        <br />
        On the next page you’ll be asked to save a payment method which will be encrypted and securely stored by Stripe,
        our payment processor.
        <br />
        <br />
        Your Intake Call appointment is fully bulk-billed, so there won’t be any charges made to your payment method
        unless you don’t attend your appointment or cancel your appointment with less than{' '}
        {IntakeAppointmentNoticePeriodInHour} hours notice. In that case there is a ${IntakeCancellationFee} late
        cancellation or non-attendance fee.
        <div>
          <ButtonMumsMatter className={styles.button} onClick={onAddPaymentDetails} status={buttonStatus}>
            Securely save my payment details
          </ButtonMumsMatter>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;
