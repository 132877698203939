import { MouseEvent as ReactMouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';

import Button from 'components/Button/Button';
import { PatientQuestionFormHeaderProps } from 'components/PatientQuestionForm/PatientQuestionForm';

import styles from './SQHeaderProgressBar.module.scss';
import ProgressBar from 'pages/OnboardingAssessment/components/Header/components/ProgressBar/ProgressBar';

const SQHeaderProgressBar = ({
  completedQuestions,
  totalQuestions,
  isFirstQuestionSet,
  isSkippable,
  onBack,
  onSkip
}: PatientQuestionFormHeaderProps) => {
  const { initialValues } = useFormikContext();

  const handleBack = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onBack();
  };

  const handleSkip = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSkip(initialValues);
  };

  return (
    <div className={styles.container} id="header">
      <div className={styles.top}>
        {!isFirstQuestionSet && (
          <Button className={styles.stepBackBtn} onClick={handleBack}>
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
            STEP BACK
          </Button>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.progress}>
          <span>
            QUESTION {completedQuestions + 1} / {totalQuestions}
          </span>
          <ProgressBar completedQuestions={completedQuestions} totalQuestions={totalQuestions} />
        </div>
        {isSkippable && (
          <Button className={styles.button} onClick={handleSkip}>
            SKIP
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SQHeaderProgressBar;
