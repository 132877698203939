import styles from './InfoSetup.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import ButtonMumsMatter, { ButtonStatusType } from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import { AddressForm } from 'components/UpdateProfileContent/components/AddressForm/AddressForm';
import { useCallback, useState } from 'react';
import { PatientAddress } from 'utils/http/PatientProfileService/Patient/patient';
import { postStripePaymentMethod } from 'utils/http/BillingService/stripe';
import { notification } from 'antd';
import EmergencyContactForm, { EmergencyContactField } from './components/EmergencyContactForm/EmergencyContactForm';
import {
  MedicareValidationErrorInterface,
  MumsMatterInfoErrorInterface,
  validationInfoForm,
  validationMedicareForm
} from './constants';
import { postKeyContact, putClientAddress } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { scrollErrorsIntoView } from 'utils/scrollToView';
import { useTranslation } from 'react-i18next';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import { useMumsMatterToken } from 'MumsMatter/utils/hooks/useMumsMatterToken';
import MedicareForm from './components/MedicareForm/MedicareForm';
import { MedicareInformation, ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';
import { retrieveUserSession } from 'MumsMatter/pages/SignUp/helper/userSession';
import CheckBox from 'components/CheckBox/CheckBox';
import ReferralDetailForm, { ReferralDetailField } from './components/ReferralDetailForm/ReferralDetailForm';
import { usePutReferralDetailMutation } from 'redux/endpoints/clinicianProfileServices/referral';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import Radio from 'components/Radio/Radio';

const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
const massageMedicareDefaultValue: MedicareInformation = {
  firstName: retrieveUserProfile[0]?.firstName || '',
  lastName: retrieveUserProfile[0]?.lastName || '',
  dateOfBirth: retrieveUserProfile[0]?.dateOfBirth || '',
  number: undefined,
  irn: undefined,
  expiryDate: ''
};

enum PrivateClient {
  Private = 'private',
  NonPrivate = 'nonPrivate'
}

const PRIVATE_CLIENT_OPTION = [
  {
    label: 'Client with a Mental Health Treatment Plan',
    value: PrivateClient.NonPrivate,
    subLabel: 'I am paying my bill by using Medicare'
  },
  { label: 'Private client', value: PrivateClient.Private, subLabel: 'I am paying my bill by myself' }
];

const InfoSetup = () => {
  const { token } = useMumsMatterToken();
  const { SIGNUP } = useMumsMatterRoutesGenerator();
  const [t] = useTranslation();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');
  const [addressValue, setAddressValue] = useState<PatientAddress>({});
  const [putReferralDetail] = usePutReferralDetailMutation();
  const [emergencyContactValue, setEmergencyContactValue] = useState<EmergencyContactField>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    relationship: ''
  });

  const [referralValue, setReferralValue] = useState<ReferralDetailField>({
    referralDate: '',
    referralFirstName: '',
    referralLastName: '',
    referralProviderNumber: '',
    practiceName: '',
    faxNumber: '',
    files: []
  });

  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [errors, setErrors] = useState<MumsMatterInfoErrorInterface>();
  const [isPrivateClient, setIsPrivateClient] = useState<PrivateClient>(PrivateClient.NonPrivate);
  const [medicareCardValue, setMedicareCardValue] = useState<MedicareInformation>(massageMedicareDefaultValue);
  const [showDva, setShowDva] = useState(false);
  const [medicareErrors, setMedicareErrors] = useState<MedicareValidationErrorInterface>();
  const [isMedicareValid, setIsMedicareValid] = useState<boolean>(false);

  const validateField = useCallback(
    (newVal: MumsMatterInfoErrorInterface) => {
      const { validationErrors, cleanValidationError } = validationInfoForm(
        newVal,
        t,
        isPrivateClient === PrivateClient.Private
      );
      setErrors(validationErrors);
      return cleanValidationError;
    },
    [t, isPrivateClient]
  );

  const handleAddressAutoComplete = (address: PatientAddress) => {
    setAddressValue((addressValue) => {
      return { ...addressValue, ...address };
    });
  };

  const handleChangeAddressValues = (key: keyof PatientAddress, value: string) => {
    setAddressValue((values) => {
      return { ...values, [key]: value };
    });
    const transformedReferralValue = {
      ...referralValue,
      files: referralValue.files.length > 0 ? referralValue.files[0].name : ''
    };
    const newAddress = { ...addressValue, [key]: value };
    validateField({ ...emergencyContactValue, ...transformedReferralValue, ...newAddress });
  };

  const handleChangeEmergencyContact = (value: EmergencyContactField) => {
    setEmergencyContactValue(value);
    const transformedReferralValue = {
      ...referralValue,
      files: referralValue.files.length > 0 ? referralValue.files[0].name : ''
    };
    validateField({ ...addressValue, ...transformedReferralValue, ...value });
  };

  const validateMedicareField = useCallback(
    (newVal: MedicareValidationErrorInterface) => {
      const { validationErrors, cleanValidationError } = validationMedicareForm(
        newVal,
        showDva,
        isPrivateClient === PrivateClient.Private
      );

      setMedicareErrors(validationErrors);
      return cleanValidationError;
    },
    [showDva, isPrivateClient]
  );

  const handleChangeMedicare = (value: MedicareInformation) => {
    const updatedMedicare = { ...medicareCardValue, ...value };

    setMedicareCardValue(updatedMedicare);

    validateMedicareField({
      firstName: updatedMedicare.firstName || '',
      lastName: updatedMedicare.lastName || '',
      dateOfBirth: updatedMedicare.dateOfBirth || '',
      number: updatedMedicare.number?.toString() || '',
      irn: updatedMedicare.irn?.toString() || '',
      dva: updatedMedicare.dva || '',
      dvaCardType: updatedMedicare.dvaCardDetails?.type || '',
      dvaCardExpiryDate: updatedMedicare.dvaCardDetails?.expiryDate || '',
      expiryDate: updatedMedicare.expiryDate || ''
    });
  };

  const handleChangeReferralDetail = (value: ReferralDetailField) => {
    setReferralValue(value);
    const transformedReferralValue = {
      ...value,
      files: value.files.length > 0 ? value.files[0].name : ''
    };
    validateField({ ...emergencyContactValue, ...addressValue, ...transformedReferralValue });
  };

  const handleSubmitInfo = async () => {
    setCheckValidation(true);

    const transformedReferralValue = {
      ...referralValue,
      files: referralValue.files.length > 0 ? referralValue.files[0].name : ''
    };
    const validate = validateField({ ...addressValue, ...transformedReferralValue, ...emergencyContactValue });
    const validateMedicare = validateMedicareField({
      firstName: medicareCardValue.firstName || '',
      lastName: medicareCardValue.lastName || '',
      dateOfBirth: medicareCardValue.dateOfBirth || '',
      number: medicareCardValue.number?.toString() || '',
      irn: medicareCardValue.irn?.toString() || '',
      dva: medicareCardValue.dva || '',
      dvaCardType: medicareCardValue.dvaCardDetails?.type || '',
      dvaCardExpiryDate: medicareCardValue.dvaCardDetails?.expiryDate || '',
      expiryDate: medicareCardValue.expiryDate || ''
    });
    scrollErrorsIntoView(validate);
    scrollErrorsIntoView(validateMedicare);
    !isMedicareValid && scrollErrorsIntoView({ medicareNumber: 'medicareErrors' });
    const checkFieldHaveError = Object.values(validate).some((value) => value !== '');
    const checkMedicareFieldHaveError = Object.values(validateMedicare).some((value) => value !== '');

    if (checkFieldHaveError || checkMedicareFieldHaveError || !token || (!isPrivateClient && !isMedicareValid)) {
      return;
    }

    try {
      setButtonStatus('active');
      //call emergency api
      await postKeyContact(token, emergencyContactValue);

      //call address api
      await putClientAddress(token, addressValue);

      if (isPrivateClient === PrivateClient.NonPrivate) {
        const referralPayload: ReferralDetailUpdateInterface = {
          files: [],
          isReferredByGP: true,
          isHaveTreatmentPlan: true,
          firstName: referralValue.referralFirstName,
          lastName: referralValue.referralLastName,
          faxNumber: referralValue.faxNumber,
          practiceName: referralValue.practiceName,
          date: referralValue.referralDate,
          medicareProviderNumber: referralValue.referralProviderNumber
        };

        if (referralValue.files) {
          referralPayload.files = await Promise.all(
            referralValue.files.map(
              (file) =>
                uploadDocumentWithEncryption(token, file) as Promise<{
                  bucketName: string;
                  fileName: string;
                  fileUrl: string;
                }>
            )
          );
        }

        await putReferralDetail({
          body: referralPayload,
          authToken: token
        }).unwrap();
      }

      const response = await postStripePaymentMethod({
        token: token,
        payload: { returnUrl: `${window.location.origin}${SIGNUP.WELCOME}` }
      });
      setButtonStatus('finished');
      window.location.href = await response.text();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to save your info. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | Additional information setup'}>
      <MumsMatterContentLayout className="mums-matter-theme">
        <div className={styles.headerWrapper}>
          <MumsMatterHeader withPadding className={styles.header} />
        </div>
        <div className={styles.container}>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Emergency Contact*</div>
            <div className={styles.desc}>
              To provide you with support we need you to provide the detail of one emergency contact. We would only
              contact this person should we be concerned about your safety. You can add additional contacts, and update
              information in your client profile.
            </div>
            <EmergencyContactForm
              values={emergencyContactValue}
              onChangeValue={handleChangeEmergencyContact}
              checkValidation={checkValidation}
              errors={errors}
            />
          </div>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Address*</div>
            <div className={styles.desc}>
              We need your primary address as part of assessing our ability to offer you the right services
            </div>
            <AddressForm
              values={addressValue}
              errors={checkValidation ? errors : undefined}
              onAddressAutoComplete={handleAddressAutoComplete}
              handleChangeValues={handleChangeAddressValues}
              formClassName={styles.formStyles}
              inputClassName={styles.inputStyles}
            />
          </div>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>I am a...</div>
            <div className={styles.desc}>
              <Radio
                vertical
                value={isPrivateClient}
                onChange={(event) => setIsPrivateClient(event.target.value as PrivateClient)}
                options={PRIVATE_CLIENT_OPTION}
              />
            </div>
          </div>
          {isPrivateClient === PrivateClient.NonPrivate && (
            <>
              <div className={styles.infoBoxContainer}>
                <div className={styles.title}>Medicare*</div>
                <div className={styles.desc}>
                  Please provide your medicare details
                  <CheckBox
                    id="showDva"
                    value={showDva}
                    onChange={(e) => setShowDva(e.target.checked)}
                    className={styles.dvaCheckBox}
                    label="Add DVA card"
                  />
                </div>
                <MedicareForm
                  medicareValue={medicareCardValue}
                  onChangeValue={handleChangeMedicare}
                  checkValidation={checkValidation}
                  errors={medicareErrors}
                  showDva={showDva}
                  setIsMedicareValid={setIsMedicareValid}
                />
              </div>
              <div className={styles.infoBoxContainer}>
                <div className={styles.title}>Your Referral Details*</div>
                <div className={styles.desc}>
                  Please provide the details for your GP who referred you to our service. You can find this information
                  on your referral letter.
                </div>
                <ReferralDetailForm
                  values={referralValue}
                  onChangeValue={handleChangeReferralDetail}
                  checkValidation={checkValidation}
                  errors={errors}
                />
              </div>
            </>
          )}
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Add payment details</div>
            <div className={styles.desc}>
              Please provide details of your credit or debit card, it will be encrypted and securely be kept on file.{' '}
              <br />
              <br />
              For clients having standalone therapy this card will be used to take payment following each session. The
              card will not be debited for clients who are case managed or who have coaching. However, we reserve the
              right to debit the card if invoices remain unpaid following all reminders being sent. <br />
              <br />
              You can update your card details at any time through the self service portal.
            </div>
          </div>
          <ButtonMumsMatter
            status={buttonStatus}
            className={styles.submitButton}
            type="submit"
            onClick={handleSubmitInfo}
          >
            Continue to save my payment details
          </ButtonMumsMatter>
        </div>
      </MumsMatterContentLayout>
      <TacklitFooter darkFooter />
    </MumsMatterHelmetWrapper>
  );
};

export default InfoSetup;
