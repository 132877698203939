import { useAuth0 } from '@auth0/auth0-react';
import styles from './SignUp.module.scss';
import Loading from 'components/Loading/Loading';
import MumsMatterCreateProfileForm from 'MumsMatter/components/MumsMatterCreateProfileForm/MumsMatterCreateProfileForm';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';

const SignUp = () => {
  const { isLoading } = useAuth0();

  return isLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | Sign Up'}>
      <div className={styles.backgroundContainer}>
        <MumsMatterContentLayout className="mums-matter-theme">
          <div className={styles.headerWrapper}>
            <MumsMatterHeader withPadding className={styles.header} />
          </div>
          <div className={styles.container}>
            <div className={styles.leftContent}>
              <MumsMatterCreateProfileForm />
            </div>
            <div className={styles.rightContent} />
          </div>
        </MumsMatterContentLayout>
        <TacklitFooter darkFooter />
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default SignUp;
