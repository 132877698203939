import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import MumsMatterHelmetWrapper from '../../components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from '../../components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from '../../components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const { PRACTITIONER } = useSelectRoutesGenerator();

  return (
    <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Appointments`}>
      <MumsMatterHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <MumsMatterContentLayout className="mums-matter-theme">
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientProfileTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default AppointmentsOverview;
