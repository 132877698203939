export const MumsMatterContactUrl = 'https://www.mumsmatterpsychology.com/contact';

export const MumsMatterSiteUrl = 'https://www.mumsmatterpsychology.com/';

export const MumsMatterSupportEmail = 'bookings@mumsmatterpsychology.com';
export const MumsMatterSupportMobile = '03 90796930';

export const MumsMatterPrivacyPolicyUrl =
  'https://a30a0df9-bf74-4da7-9383-742a583d6135.filesusr.com/ugd/0dbe01_d3ef4bade51942559cbb91b2eaaab2b6.pdf';

export const MumsMatterAccountId = process.env.REACT_APP_ACCOUNT_ID_MUMS_MATTER || '67b2f1f3659e6aea5dce3897';

export const MumsMatterSlugUrl = 'mums_matter';

export const IntakeCancellationFee = '100.00';
export const IntakeAppointmentNoticePeriodInHour = '24';

// Intake Config
export const MumsMatterIntakeCallTags = {
  NO_INTAKE_CALL: 'Waitlist',
  INTAKE_CALL: 'Intake Call'
};
