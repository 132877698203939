import { useAuth0 } from '@auth0/auth0-react';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { useCallback, useEffect, useState } from 'react';
import { claimReservedAppointments } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { retrieveUserSession } from '../../helper/userSession';
import { notification } from 'antd';
import { isValidVoucherCode } from '../../components/CreateProfileForm/constants';

export const useClaimReservation = () => {
  const [isClaimingReservation, setIsClaimingReservation] = useState<boolean>(true);
  const [claimStatusCode, setClaimStatusCode] = useState<number>(0);
  const [claimReservationDetails, setClaimReservation] = useState<{
    isBookingRuleError?: boolean;
    isVoucherCodeValid?: boolean;
  }>({});

  const { reserveId, accountId } = useReserveAppointmentData();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const fetchClaimingReservation = useCallback(
    async (authToken: string) => {
      if (reserveId && accountId && authToken) {
        setIsClaimingReservation(true);

        const voucherCode = retrieveUserSession().voucherCode;
        const isVoucherCodeValid = !!voucherCode && isValidVoucherCode(voucherCode);

        try {
          const { statusCode, response } = await claimReservedAppointments({
            accountId,
            reservationId: reserveId,
            authToken,
            voucherCode,
            shouldGenerateCheckoutSession: false
          });

          setClaimStatusCode(statusCode);

          setClaimReservation({
            ...(response?.isBookingRuleError && { isBookingRuleError: response.isBookingRuleError }),
            isVoucherCodeValid
          });

          if (statusCode !== 200 && statusCode !== 403) {
            const errorMessage = `Claim reservation (ID: ${reserveId}) failed with status. ${statusCode}`;
            console.error(errorMessage);
            notification.error({ message: errorMessage });
          }
        } catch (ex) {
          console.error(ex);
        }
        setIsClaimingReservation(false);
      }
    },
    [reserveId, accountId]
  );

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const loginToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          fetchClaimingReservation(loginToken);
        })();
      } else {
        const storedTempAuthToken = retrieveUserSession().authToken;
        if (storedTempAuthToken) {
          fetchClaimingReservation(storedTempAuthToken);
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, fetchClaimingReservation]);

  return { isClaimingReservation, claimStatusCode, claimReservationDetails };
};
