import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { Button } from 'antd';
import styles from './MumsMatterHomePage.module.scss';
import TaskBox from 'components/TaskBox/TaskBox';
import { useTranslation } from 'react-i18next';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import WelcomeMessage from 'pages/PatientPage/HomePage/components/WelcomeMessage/WelcomeMessage';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import HomeNoTaskBox from '../../../components/HomeNoTaskBox/HomeNoTaskBox';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { CLIENT_BRAND, CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { MumsMatterSupportMobile } from 'MumsMatter/utils/env/MumsMatterEnv';
import { formatActionCount } from 'utils/general';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const MumsMatterHomePage = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );
  const { CONSENT, SURVEY, ONBOARDING, PSYCHOMETRIC } = useMumsMatterRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';
  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const countOfActions =
    (clientTasks ? Object.values(clientTasks).filter((arr: any[]) => arr?.length > 0).length : 0) +
    (surveyList?.length ? 1 : 0) +
    (psychometricList?.length ? 1 : 0);

  const hasTask =
    (clientTasks && (clientTasks.consentList.length > 0 || clientTasks.assessmentList.length > 0)) ||
    (surveyList && surveyList.length) ||
    psychometricList.length > 0;

  return (
    <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Welcome ${user ? user.name : ''}`}>
      <MumsMatterHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <MumsMatterContentLayout className="mums-matter-theme">
          {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
            <Button
              className={styles.fixedLeftBtn}
              type={'primary'}
              disabled={isAttachedClinicianDetailLoading}
              onClick={() => {
                navigate(`/${attachedClinicianDetail.clinician?.slugUrl}/checkIn`);
              }}
            >
              <div className={styles.newJournalBtn}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                NEW JOURNAL ENTRY
              </div>
            </Button>
          )}
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              {isContentLoading || isCompletedCheckInStatusLoading ? (
                <div className={styles.loading}>
                  <LoadingDot />
                </div>
              ) : (
                <div className={styles.contentBox}>
                  {attachedClinicianDetail && (
                    <WelcomeMessage
                      clinicianName={clinicianName}
                      clinicianDetail={attachedClinicianDetail}
                      completedCheckInStatus={completedCheckInStatus}
                      className={styles.welcomeMessage}
                    />
                  )}
                  {hasTask ? (
                    <div className={styles.taskListContainer}>
                      <div className={styles.taskListTitle}>
                        <div className={styles.title}>Your pre session activities</div>
                        <strong>{clinicianName}</strong> has asked you to complete{' '}
                        <strong>{formatActionCount({ count: countOfActions })}.</strong>
                      </div>
                      <div className={styles.taskListBox}>
                        {clientTasks && (
                          <>
                            {clientTasks.consentList.length > 0 && (
                              <TaskBox
                                title={t('label.esign_client_consent_form')}
                                desc={formatActionCount({ count: clientTasks.consentList.length })}
                                onClick={() => navigate(CONSENT.BASE)}
                                containerClassName={styles.taskBoxClassName}
                              />
                            )}
                            {clientTasks.assessmentList.length > 0 && (
                              <TaskBox
                                title={'Onboarding assessment'}
                                desc={formatActionCount({ count: clientTasks.assessmentList.length })}
                                onClick={() => navigate(ONBOARDING.BASE)}
                                containerClassName={styles.taskBoxClassName}
                              />
                            )}
                          </>
                        )}
                        {surveyList && !!surveyList.length && (
                          <TaskBox
                            title={'Forms'}
                            desc={formatActionCount({ count: surveyList.length })}
                            onClick={() => navigate(SURVEY.BASE)}
                            containerClassName={styles.taskBoxClassName}
                          />
                        )}
                        {psychometricList.length > 0 && (
                          <TaskBox
                            title={'Psychometrics'}
                            desc={formatActionCount({ count: psychometricList.length })}
                            onClick={() => navigate(PSYCHOMETRIC.BASE)}
                            containerClassName={styles.taskBoxClassName}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <HomeNoTaskBox
                      className={styles.homeNoTaskBox}
                      taskContent={[]}
                      noTaskMessage={
                        <div className={styles.noTaskMassage}>
                          <div className={styles.label}>
                            Here in your <b>{CLIENT_BRAND_LABEL[CLIENT_BRAND]} account </b> you can manage all aspects
                            of your care program.
                          </div>
                          <div className={styles.taskLabel}>
                            You currently have <span className={styles.number}>0</span> outstanding tasks
                          </div>
                          <div className={styles.label}>
                            If you have any questions, you can reach out to our Client Services Team on{' '}
                            <b>{MumsMatterSupportMobile}.</b>
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </MumsMatterContentLayout>
        <TacklitFooter darkFooter />
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default MumsMatterHomePage;
