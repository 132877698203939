import { clinicianProfileServicesApiSlice, CPSTagTypes } from '../../services/clinicianProfileServicesApiSlice';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';

export const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticeInfo: builder.query<PublicPracticeProfile, { slugUrlOrAccountId: string }>({
      query: ({ slugUrlOrAccountId }) => ({
        url: `public/accounts/${slugUrlOrAccountId}/practiceInfo`
      }),
      providesTags: [CPSTagTypes.AccountPracticeInfo]
    })
  })
});

export const { useGetPracticeInfoQuery } = clientRecordsApiSlice;
