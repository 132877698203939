import { BentResponse } from 'bent';
import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_PATIENT_PROFILE_SERVICE_URL;

export const postPatientAvatar = (payload: { avatar: string }) =>
  httpClient('POST', url!, 201)('/avatar', payload) as Promise<BentResponse>;

export const postPatientSignup = (body: {
  accountId: string;
  avatar: string;
  mobile: string;
  email: string;
  name: string;
  password: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  postcode: string;
}) => {
  const { accountId, email, name, password, avatar, mobile, dateOfBirth, firstName, lastName, postcode } = body;
  const payload = {
    accountId,
    email,
    name,
    password,
    avatar,
    mobile,
    dateOfBirth,
    firstName,
    lastName,
    postcode
  };

  return httpClient('POST', url!, [201, 404])('/patient', payload) as Promise<BentResponse>;
};

export interface EngageSignUpPayload {
  clinicianId?: string;
  clientRecord: {
    avatar: string;
    mobile: string;
    email: string;
    name: string;
    password: string;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    postcode: string;
    communicationPreference: string;
    tag?: string;
    timeZone?: string;
    appointmentTypeId?: string;
  };
}

export interface SHSignUpWithInviteRequestBody {
  data: {
    communicationPreference: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    password: string;
  };
  smsOtp: string;
}

export const postPatientSignupWithoutInvitation = (accountId: string, payload: EngageSignUpPayload) => {
  return httpClient('POST', url!, [200])(`/accounts/${accountId}/patients`, payload) as Promise<BentResponse>;
};

export interface PatientAddress {
  line1?: string;
  line2?: string;
  state?: string;
  suburb?: string;
  postcode?: string;
  country?: string;
}

export interface PutPatientPayload {
  avatar?: string;
  mobileNumber?: string;
  email?: string;
  name?: string;
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
  address?: PatientAddress;
  timeZone?: string;
}

export const putPatient = (token: string, patientInfo: PutPatientPayload) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/patients/me`,
    patientInfo
  ) as Promise<BentResponse>;
};

export const postPatientSignUpWithInvitation = (accountId: string, payload: SHSignUpWithInviteRequestBody) => {
  return httpClient(
    'POST',
    url!,
    [200, 404]
  )(`/accounts/${accountId}/patients:invited`, payload) as Promise<BentResponse>;
};

export interface AdditionalProfile {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  role: string;
  isPrimaryContact: boolean;
}

export interface EmergencyContactDetail {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  relationship: string;
}

export interface KeyClientContact {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  tags: string[];
}

export interface clientRecordOnlySignUpPayload {
  clinicianId?: string;
  clientRecord: {
    avatar: string;
    mobile?: string;
    email?: string;
    name: string;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    postcode: string;
    communicationPreference: string;
    caseId?: string;
    providerDetails?: string;
    referralBackground?: string;
    tag?: string;
    timeZone?: string;
    appointmentTypeId?: string;
    officeLocation?: string;
    purchaseOrderNo?: string;
    recordType?: ClientRecordType;
    additionalProfiles?: AdditionalProfile[];
    keyClientContacts?: KeyClientContact[];
  };
  isSendInvite?: boolean;
}

export const postClientRecordSignUp = (accountId: string, payload: clientRecordOnlySignUpPayload) => {
  return httpClient('POST', url!, [200])(
    `/accounts/${accountId}/patients:recordOnly`,
    payload
  ) as Promise<BentResponse>;
};
