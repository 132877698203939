import { InputHTMLAttributes, ReactNode, useState } from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';
import Button from 'components/v2/Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export enum InputVariant {
  BOX = 'box',
  LINE = 'line'
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClass?: string;
  label?: string;
  labelClass?: string;
  hasError?: boolean;
  externalError?: ReactNode | string;
  noSpacing?: boolean;
  allowShowPassword?: boolean;
  showPasswordButtonClass?: string;
  variant?: InputVariant;
}

const Input = ({
  className,
  inputClass,
  label,
  labelClass,
  placeholder,
  hasError,
  externalError,
  noSpacing,
  allowShowPassword,
  showPasswordButtonClass,
  variant = InputVariant.LINE,
  ...props
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const containerClasses = classnames(styles.container, className);
  const inputClasses = classnames(
    variant === InputVariant.BOX ? styles.inputBox : styles.input,
    (!!externalError || hasError) && styles.error,
    noSpacing && styles.noSpacing,
    inputClass
  );
  const labelClasses = classnames(
    variant === InputVariant.BOX && styles.boxStaticLabel,
    placeholder ? styles.staticLabel : styles.label,
    (!!externalError || hasError) && styles.error,
    labelClass
  );
  const showPasswordButtonClasses = classnames(styles.showPasswordButton, showPasswordButtonClass);

  return (
    <div className={styles.wrapper}>
      <div className={containerClasses}>
        <input
          className={inputClasses}
          placeholder={placeholder}
          {...props}
          {...(allowShowPassword && props.type === 'password' && isPasswordVisible
            ? { type: '' }
            : { type: props.type })}
        />
        {label && <div className={labelClasses}>{label}</div>}
        {allowShowPassword && props.type === 'password' && (
          <Button
            className={showPasswordButtonClasses}
            variant="secondary"
            icon={isPasswordVisible ? 'visibility' : 'visibility_off'}
            type="button"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        )}
      </div>
      <ErrorMessage className={styles.errorMessage} error={externalError} visible={!!externalError || !!hasError} />
    </div>
  );
};

export default Input;
