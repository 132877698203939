import styles from './MumsMatterWrapper.module.scss';
import classNames from 'classnames';

interface MumsMatterWrapperProps {
  children?: any;
  blueBg?: boolean;
  greenBg?: boolean;
}

const MumsMatterWrapper = ({ children, blueBg, greenBg }: MumsMatterWrapperProps) => (
  <div className={classNames(styles.container, blueBg && styles.colorBg, greenBg && styles.greenBg)}>{children}</div>
);

export default MumsMatterWrapper;
