import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Gateway from '../components/Gateway/Gateway';
import Header from '../components/Header/Header';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import { useFetchOnboardingDetails } from './hooks/GetOnboardingDetails';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import OnboardingQuestionForm from '../components/OnboardingQuestionForm/OnboardingQuestionForm';

import styles from './Onboarding.module.scss';
import { useGetAccessToken } from '../../../utils/hooks/token';
import BackgroundForm from '../../../components/BackgroundForm/BackgroundForm';
import { BackgroundResponse } from 'interfaces/Assessment/OnboardingAssessment';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import { putBackgroundResponses } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { notification } from 'antd';
import ClientHeader from '../../../components/ClientHeader/ClientHeader';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_MUMS_MATTER_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import CaWHeader from '../../../CaW/components/CaWHeader/CaWHeader';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import classNames from 'classnames';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import MumsMatterHeader from '../../../MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';

const Onboarding = () => {
  const { user } = useAuth0();
  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );
  const { token } = useGetAccessToken();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const [viewState, setViewState] = useState<
    'done' | 'saveAndExit' | 'welcomeBack' | 'questions' | 'background' | 'welcomeSurvey'
  >('welcomeSurvey');
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const { onboardingAssessmentDetails, surveyNote, isOnboardingAssessmentLoading, setOnboardingAssessmentDetails } =
    useFetchOnboardingDetails(setViewState, clientRecordId);

  const handleSaveAndExit = () => {
    setViewState('saveAndExit');
  };

  const handleReturnToOnboardingQuestions = () => {
    setViewState('questions');
  };

  const handleUpdateStatusDone = () => {
    setViewState('done');
  };

  const handleCompleteBackground = async (val: BackgroundResponse, file: File) => {
    setPageSubmitStatus('active');
    if (!onboardingAssessmentDetails) {
      return;
    }
    try {
      const bgData = val;
      if (val.referral?.code === 'yes') {
        const uploadedFile =
          file.size > 0
            ? ((await uploadDocumentWithEncryption(token, file)) as {
                bucketName: string;
                fileName: string;
                fileUrl: string;
              })
            : undefined;
        bgData.referral = {
          ...val.referral,
          file: uploadedFile
        };
      }
      onboardingAssessmentDetails.assessment.onboardingQuestions.backgroundResponse = bgData;
      setOnboardingAssessmentDetails(onboardingAssessmentDetails);
      await putBackgroundResponses(token, onboardingAssessmentDetails._id, bgData);
      setTimeout(() => {
        setPageSubmitStatus('finished');

        const isHaveQuestion = onboardingAssessmentDetails.assessment.onboardingQuestions.assessmentField.find(
          (obj: any) => {
            return obj.sections && obj.sections.find((sec: any) => sec.questions.length > 0);
          }
        );
        if (isHaveQuestion) {
          setViewState('questions');
        } else {
          setViewState('done');
        }
      }, 500);
      setTimeout(() => {
        setPageSubmitStatus('');
      }, 1000);
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Background details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setPageSubmitStatus('');
      }, 1000);
    }
  };

  const handleReturnToBackground = () => {
    setViewState('background');
  };

  const getStep = (state: string) => {
    switch (state) {
      case 'questions':
        return (
          <div
            className={
              IS_CAW_APP
                ? styles.cawContainer
                : IS_SOMEONE_HEALTH_APP
                ? styles.shContainer
                : IS_EASE_APP
                ? styles.easeContainer
                : IS_RECHARGE_APP
                ? styles.rechargeContainer
                : IS_SELECT_APP
                ? styles.selectContainer
                : IS_PORTOBELLO_APP
                ? styles.portobelloContainer
                : IS_MUMS_MATTER_APP
                ? styles.mumsMatterContainer
                : styles.container
            }
          >
            <ContentLayout className={styles.content}>
              <OnboardingQuestionForm
                assessment={onboardingAssessmentDetails}
                clinician={attachedClinicianDetail?.clinician}
                clinicianName={clinicianName}
                header={Header}
                onUpdateAssessment={handleUpdateAssessment}
                onSaveAndExit={handleSaveAndExit}
                onUpdateStatusDone={handleUpdateStatusDone}
                token={token}
              />
            </ContentLayout>
          </div>
        );
      case 'background':
        if (!onboardingAssessmentDetails?.assessment.onboardingQuestions.backgroundResponse) {
          setViewState('questions');
          return <></>;
        }
        return (
          <>
            {IS_HELM_APP ? (
              <div className={styles.headerWrapper}>
                <HelmHeader noWrapper logoWithoutText whiteFont whiteLogo withPadding withMenu homeLink />
              </div>
            ) : IS_SOMEONE_HEALTH_APP ? (
              <div className={styles.headerWrapper}>
                <SomeoneHealthHeader withPadding withMenu homeLink />
              </div>
            ) : IS_CAW_APP ? (
              <div className={styles.headerWrapper}>
                <CaWHeader withPadding withMenu homeLink blueMenu />
              </div>
            ) : IS_EASE_APP ? (
              <div className={styles.headerWrapper}>
                <EaseHeader withPadding withMenu homeLink greenMenu />
              </div>
            ) : IS_RECHARGE_APP ? (
              <div className={styles.headerWrapper}>
                <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
              </div>
            ) : IS_SELECT_APP ? (
              <div className={styles.headerWrapper}>
                <SelectHeader withPadding withMenu homeLink whiteFont />
              </div>
            ) : IS_PORTOBELLO_APP ? (
              <div className={styles.headerWrapper}>
                <PortobelloHeader withPadding withMenu homeLink />
              </div>
            ) : IS_MUMS_MATTER_APP ? (
              <div className={styles.headerWrapper}>
                <MumsMatterHeader withPadding withMenu homeLink />
              </div>
            ) : (
              <ClientHeader />
            )}
            {attachedClinicianDetail && (
              <div className={styles.bgContainer}>
                <div className={styles.bgContent}>
                  <BackgroundForm
                    clinicianDetail={attachedClinicianDetail}
                    backgroundResponse={onboardingAssessmentDetails?.assessment.onboardingQuestions.backgroundResponse}
                    pageSubmitStatus={pageSubmitStatus}
                    onCompleteBackground={handleCompleteBackground}
                    noHeader
                    autoHeight
                  />
                </div>
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const handleUpdateAssessment = (
    assessmentId: string,
    responses: { questionId: string; response: { value: any; otherValue?: any } }[]
  ) => {
    if (onboardingAssessmentDetails) {
      const newFirstAssessment = { ...onboardingAssessmentDetails };

      const foundQuestionSet =
        newFirstAssessment.assessment.clinicalAssessments.find((assessment) => assessment.id === assessmentId) ||
        newFirstAssessment.assessment.onboardingQuestions.assessmentField.find(
          (assessment) => assessment.id === assessmentId
        );

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }

      setOnboardingAssessmentDetails(newFirstAssessment);

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newFirstAssessment };
    }
  };

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return isOnboardingAssessmentLoading || isAttachedClinicianDetailLoading ? (
    <div
      className={
        IS_CAW_APP
          ? styles.cawLoading
          : IS_SOMEONE_HEALTH_APP
          ? styles.shLoading
          : IS_EASE_APP
          ? styles.easeLoading
          : IS_RECHARGE_APP
          ? styles.rechargeLoading
          : IS_SELECT_APP
          ? styles.selectLoading
          : IS_PORTOBELLO_APP
          ? styles.portobelloLoading
          : IS_MUMS_MATTER_APP
          ? styles.mumsMatterLoading
          : styles.loading
      }
    >
      <LoadingCircle />
    </div>
  ) : viewState === 'questions' || viewState === 'background' ? (
    <div
      className={classNames(
        IS_CAW_APP
          ? styles.cawContainer
          : IS_SOMEONE_HEALTH_APP
          ? styles.shContainer
          : IS_EASE_APP
          ? styles.easeContainer
          : IS_RECHARGE_APP
          ? styles.rechargeContainer
          : IS_SELECT_APP
          ? styles.selectContainer
          : IS_PORTOBELLO_APP
          ? styles.portobelloContainer
          : IS_MUMS_MATTER_APP
          ? styles.mumsMatterContainer
          : styles.container,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme',
        IS_PORTOBELLO_APP && 'portobello-theme',
        IS_MUMS_MATTER_APP && 'mums-matter-theme'
      )}
    >
      {getStep(viewState)}
    </div>
  ) : (
    <div
      className={
        IS_CAW_APP
          ? styles.cawGatewayContainer
          : IS_SOMEONE_HEALTH_APP
          ? styles.shGatewayContainer
          : IS_EASE_APP
          ? styles.easeGatewayContainer
          : IS_RECHARGE_APP
          ? styles.rechargeGatewayContainer
          : IS_SELECT_APP
          ? styles.selectGatewayContainer
          : IS_PORTOBELLO_APP
          ? styles.portobelloGatewayContainer
          : IS_MUMS_MATTER_APP
          ? styles.mumsMatterGatewayContainer
          : styles.gatewayContainer
      }
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : undefined
      }
    >
      <Gateway
        state={viewState}
        surveyNote={surveyNote}
        practiceName={attachedClinicianDetail?.practice?.name}
        clinicianName={clinicianName}
        onReturnToOnboardingQuestions={handleReturnToOnboardingQuestions}
        onReturnToBackground={handleReturnToBackground}
        assessment={onboardingAssessmentDetails}
      />
    </div>
  );
};

export default Onboarding;
