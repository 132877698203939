import styles from './ReferralDetailForm.module.scss';

import Input from 'components/Input/Input';
import React, { ChangeEvent, useCallback } from 'react';
import { MumsMatterInfoErrorInterface } from '../../constants';
import DateInput, { DateFormat } from 'components/Field/DateInput/DateInput';
import classNames from 'classnames';
import { checkReferralFileSize, getDownloadFileName } from 'utils/uploadFile';
import { notification } from 'antd';
import ButtonMumsMatter from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

export interface ReferralDetailField {
  referralDate: string;
  referralFirstName: string;
  referralLastName: string;
  referralProviderNumber: string;
  practiceName: string;
  faxNumber: string;
  files: File[];
}

interface ReferralDetailFormInterface {
  values: ReferralDetailField;
  onChangeValue: (values: ReferralDetailField) => void;
  checkValidation: boolean;
  errors?: MumsMatterInfoErrorInterface;
}

const ReferralDetailForm = ({ values, onChangeValue, checkValidation, errors }: ReferralDetailFormInterface) => {
  const handleFieldChange = useCallback(
    (fieldName: keyof ReferralDetailField, value: string | boolean) => {
      const updatedField = {
        ...values,
        [fieldName]: value
      };
      onChangeValue(updatedField);
    },
    [values, onChangeValue]
  );

  const handleUploadReferral = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const validatedFiles: File[] = [];
      files
        .filter(({ name }) => !values.files.map((item) => item.name).includes(name))
        .forEach((file) => {
          const fileSizeValidate = checkReferralFileSize(file);
          if (!fileSizeValidate.valid) {
            notification.error({ message: fileSizeValidate.message });
          } else {
            validatedFiles.push(file);
          }
        });
      onChangeValue({
        ...values,
        files: [...values.files, ...validatedFiles]
      });
    }
    event.target.value = '';
  };

  const renderFileName = (index: number, fileName: string, buttonLabel: string, buttonAction: () => void) => {
    return (
      <div key={index} className={styles.file}>
        <div className={styles.fileName}>
          <i className={`material-icons ${styles.icon}`}>attach_file</i>
          {getDownloadFileName(fileName)}
        </div>
        <ButtonMumsMatter icon={'close'} variant={'outlined'} size={'small'} onClick={buttonAction}>
          {buttonLabel}
        </ButtonMumsMatter>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.rowWrapper, styles.dateWrapper)}>
        <DateInput
          format={DateFormat.DAY_MONTH_YEAR}
          label={'Referral Date*'}
          id="referralDate"
          value={values.referralDate || ''}
          onChange={(value: string) => {
            handleFieldChange('referralDate', value);
          }}
          checkValidation={checkValidation && !!errors?.referralDate}
          error={checkValidation ? errors?.referralDate : ''}
          emptyErrorMessage={checkValidation ? 'Please enter referral date' : ''}
        />
      </div>
      <div className={styles.rowWrapper}>
        <Input
          id="referralFirstName"
          inputClass={styles.customInput}
          className={styles.fieldWrapper}
          label="GP First Name*"
          name="referralFirstName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('referralFirstName', e.target.value);
          }}
          externalError={checkValidation ? errors?.referralFirstName : ''}
        />
        <Input
          id="referralLastName"
          inputClass={styles.customInput}
          className={styles.fieldWrapper}
          label="GP Last Name*"
          name="referralLastName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('referralLastName', e.target.value);
          }}
          externalError={checkValidation ? errors?.referralLastName : ''}
        />
        <Input
          id="referralProviderNumber"
          inputClass={styles.customInput}
          className={styles.fieldWrapper}
          label="GP Provider Number*"
          name="referralProviderNumber"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('referralProviderNumber', e.target.value);
          }}
          maxLength={8}
          externalError={checkValidation ? errors?.referralProviderNumber : ''}
        />
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.fieldWrapper}>
          <Input
            id="practiceName"
            inputClass={styles.customInput}
            className={styles.fieldWrapper}
            label="Name of GP Practice*"
            name="practiceName"
            required
            labelClass={styles.inputLabel}
            onChange={(e) => {
              handleFieldChange('practiceName', e.target.value);
            }}
            externalError={checkValidation ? errors?.practiceName : ''}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <Input
            id="faxNumber"
            inputClass={styles.customInput}
            className={styles.fieldWrapper}
            label="Fax Number"
            name="faxNumber"
            required
            labelClass={styles.inputLabel}
            onChange={(e) => {
              handleFieldChange('faxNumber', e.target.value);
            }}
          />
        </div>
      </div>
      <div className={classNames(styles.rowWrapper, styles.referralFileWrapper)}>
        <div>
          <div className={styles.uploadButtonContainer}>
            <input
              className={styles.uploadInput}
              type={'file'}
              id={'files'}
              onChange={handleUploadReferral}
              accept={'.pdf,.docx,image/png,image/jpeg'}
              multiple
            />
            <label htmlFor={'files'}>
              <div className={styles.uploadButton}>
                <i className={`material-icons`}>upload</i>
                <div className={styles.publishLabel}>Upload referral Documents</div>
              </div>
            </label>
          </div>
          {checkValidation && <ErrorMessage error={errors?.files} visible={!!errors?.files} />}
          {values.files &&
            values.files.map((file, index) =>
              renderFileName(index, file.name, 'Remove', () =>
                onChangeValue({
                  ...values,
                  files: values.files.filter((fil) => fil.name !== file.name)
                })
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default ReferralDetailForm;
