import styles from './IntakeSuccessPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useGetAccountClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { Skeleton } from 'antd';

import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import { MumsMatterSlugUrl } from 'MumsMatter/utils/env/MumsMatterEnv';
import ButtonMumsMatter from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';

const IntakeSuccessPage = () => {
  const navigate = useNavigate();
  const { APPOINTMENT_OVERVIEW } = useMumsMatterRoutesGenerator();
  const { clinicianId } = useReserveGPAppointmentData();

  const {
    data: gpDetails,
    isLoading: isGPDetailsLoading,
    isFetching: isGPDetailsFetching
  } = useGetAccountClinicianDetailsByIdQuery(
    { slugUrl: MumsMatterSlugUrl, clinicianId: clinicianId || '' },
    { skip: !clinicianId }
  );

  const handleAppointmentRedirection = () => {
    navigate(APPOINTMENT_OVERVIEW);
  };

  return (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | Intake Call'}>
      <MumsMatterHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <MumsMatterContentLayout className={styles.contentLayoutWrapper}>
          <div className={styles.welcomeMessage}>Intake Call</div>
          <div className={styles.content}>
            <div className={styles.gpInfoWrapper}>
              {isGPDetailsLoading || isGPDetailsFetching ? (
                <div className={styles.gpImageWrapper}>
                  <Skeleton.Input active className={styles.gpImageLoading} />
                </div>
              ) : (
                gpDetails?.avatar && (
                  <div className={styles.gpImageWrapper}>
                    <img className={styles.gpImage} src={gpDetails?.avatar} alt={'gp avatar'} />
                  </div>
                )
              )}
              {isGPDetailsLoading || isGPDetailsFetching ? (
                <Skeleton.Input active className={styles.gpTitleLoading} />
              ) : (
                <div className={styles.gpTitle}>
                  Nice work! Your GP appointment with {gpDetails?.title} {gpDetails?.name} has been confirmed.
                </div>
              )}
            </div>
            <div className={styles.nextInfoWrapper}>
              <div className={styles.nextTitle}>What’s next?</div>
              <div className={styles.nextInfo}>
                Your GP will review the information you have provided and assess your suitability for a Mental Health
                Treatment Plan.
                <br />
                <br />
                In your appointment they will confirm if you are eligible and share a referral with your preferred
                psychologist. If you’re not eligible, your GP will inform you in your appointment and discuss other
                options available to you.
                <br />
                <br />
                Once you’ve attended your GP appointment you’ll have access to book in a time with your chosen
                psychologist.
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <ButtonMumsMatter className={styles.button} onClick={handleAppointmentRedirection}>
                View my appointments
              </ButtonMumsMatter>
            </div>
          </div>
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default IntakeSuccessPage;
