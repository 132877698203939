import Input, { InputVariant } from 'components/Input/Input';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './MedicareForm.module.scss';
import medicareImg from 'assets/images/medicare.png';
import DateInput, { DateFormat } from 'components/Field/DateInput/DateInput';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Select from 'components/Select/Select';
import moment from 'moment';
import { DvaCardType, MedicareInformation } from 'components/UpdateProfileContent/interface';
import { useMumsMatterToken } from 'MumsMatter/utils/hooks/useMumsMatterToken';
import { MedicareValidationErrorInterface } from '../../constants';
import VerificationStatus from 'components/VerificationStatus/VerificationStatus';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { usePutClientMedicareMutation } from 'redux/endpoints/clinicianProfileServices/client';

interface MedicareFormProps {
  medicareValue: MedicareInformation;
  onChangeValue: (data: MedicareInformation) => void;
  checkValidation?: boolean;
  errors?: MedicareValidationErrorInterface;
  showDva?: boolean;
  setIsMedicareValid: (errors: boolean) => void;
}

const MedicareForm = ({
  onChangeValue,
  medicareValue,
  checkValidation,
  errors,
  showDva,
  setIsMedicareValid
}: MedicareFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>();
  const [putClientMedicare] = usePutClientMedicareMutation();

  const { token } = useMumsMatterToken();

  const handleFieldChange = useCallback(
    (fieldName: keyof MedicareInformation, value: string | number | object) => {
      if (medicareValue[fieldName] !== value) {
        onChangeValue({ ...medicareValue, [fieldName]: value });
      }
    },
    [medicareValue, onChangeValue]
  );

  const saveMedicareInfo = useCallback(
    async (medicare: MedicareInformation) => {
      setIsLoading(true);
      try {
        const payloadData: MedicareInformation = {
          firstName: medicare.firstName,
          lastName: medicare.lastName,
          number: medicare.number,
          irn: medicare.irn,
          expiryDate: medicare.expiryDate,
          ...(showDva && {
            dva: medicare.dva,
            dvaCardDetails: {
              ...medicare.dvaCardDetails,
              expiryDate: medicare.dvaCardDetails?.expiryDate
            }
          }),
          dateOfBirth: moment(medicare.dateOfBirth, DateFormat.DAY_MONTH_YEAR).format(DateFormat.YEAR_MONTH_DAY)
        };

        const data = await putClientMedicare({
          body: payloadData,
          authToken: token
        }).unwrap();

        setMedicareErrorMessage(data?.status);

        const isMedicareValid =
          (!showDva && (data.status?.medicare?.code === 0 || data.status?.medicare?.code === 9626)) ||
          (showDva &&
            data.status?.medicare?.code !== undefined &&
            data.status?.medicare?.code !== null &&
            (data.status?.medicare?.code === 0 || data.status?.medicare?.code === 9626) &&
            data.status?.dva?.code === 0) ||
          false;

        setIsMedicareValid(isMedicareValid);
      } catch (error) {
        const errorMessage = {
          medicare: {
            code: 9633
          }
        };

        setMedicareErrorMessage(errorMessage);
        setIsMedicareValid(false);
      } finally {
        setIsLoading(false);
      }
    },
    [putClientMedicare, setIsMedicareValid, token, showDva]
  );

  const callMedicareUpdating = useMemo(
    () =>
      debounce((formValues: MedicareInformation) => {
        if (token) {
          saveMedicareInfo(formValues);
        }
      }, 2000),
    [token, saveMedicareInfo]
  );

  useEffect(() => {
    if (medicareValue) {
      const { number, irn, expiryDate, dva } = medicareValue;
      if ((number && irn && expiryDate !== '') || dva) {
        callMedicareUpdating(medicareValue);
      }
    }
  }, [medicareValue, callMedicareUpdating]);

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <div className={styles.nameRowWrapper}>
          <Input
            id="medicareFirstName"
            className={styles.fieldWrapper}
            label="First Name*"
            name="firstName"
            value={medicareValue.firstName}
            required
            onChange={(e) => {
              handleFieldChange('firstName', e.target.value);
            }}
            externalError={checkValidation ? errors?.firstName : ''}
          />
          <Input
            id="medicareLastName"
            className={styles.fieldWrapper}
            label="Last Name*"
            name="lastName"
            value={medicareValue.lastName}
            required
            onChange={(e) => {
              handleFieldChange('lastName', e.target.value);
            }}
            externalError={checkValidation ? errors?.lastName : ''}
          />
        </div>
        <div className={styles.dobInfo}>
          <DateInput
            id="medicareDateOfBirth"
            label={'Date of Birth*'}
            value={medicareValue.dateOfBirth || ''}
            onChange={(e) => handleFieldChange('dateOfBirth', e)}
            format={DateFormat.DAY_MONTH_YEAR}
            checkValidation={checkValidation}
            error={checkValidation ? errors?.dateOfBirth : ''}
            emptyErrorMessage={checkValidation ? 'Please enter date of birth' : ''}
          />
        </div>
        <div className={styles.medicareInfo}>
          <Input
            id={'medicareNumber'}
            label={'Medicare Card Number*'}
            name="medicareNumber"
            variant={InputVariant.BOX}
            value={medicareValue.number}
            required
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '');
              handleFieldChange('number', value);
            }}
            externalError={checkValidation ? errors?.number : ''}
            maxLength={12}
            inputMode="numeric"
          />
          <Input
            className={styles.irn}
            id={'irn'}
            label={'IRN*'}
            variant={InputVariant.BOX}
            value={medicareValue.irn}
            required
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '');
              handleFieldChange('irn', value);
            }}
            externalError={checkValidation ? errors?.irn : ''}
            maxLength={3}
            inputMode="numeric"
          />
        </div>

        <div className={styles.fieldWrapper}>
          <DateInput
            id="medicareExpiryDate"
            label={'Expiry Date*'}
            value={medicareValue.expiryDate || ''}
            onChange={(e) => handleFieldChange('expiryDate', e)}
            format={DateFormat.MONTH_YEAR}
            checkValidation={checkValidation}
            error={checkValidation ? errors?.expiryDate : ''}
            emptyErrorMessage={checkValidation ? 'Please enter medicare expiry date' : ''}
          />
        </div>

        {showDva && (
          <div className={classNames(styles.fieldWrapper, styles.dvaContainer)}>
            <Input
              label={'DVA File Number'}
              id={'dva'}
              onChange={(e) => {
                handleFieldChange('dva', e.target.value);
              }}
              required
              value={medicareValue.dva}
              externalError={checkValidation ? errors?.dva : ''}
            />

            <div>
              <Select
                id={'dvaCardType'}
                label={'DVA Card Type'}
                isSearchable={false}
                options={Object.values(DvaCardType).map((val) => ({
                  label: val || '',
                  value: val || ''
                }))}
                onChange={(value) =>
                  handleFieldChange('dvaCardDetails', { ...medicareValue.dvaCardDetails, type: value?.value })
                }
                hasError={checkValidation && !!errors?.dvaCardType}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%'
                  }),
                  control: (controlBase: any) => ({
                    ...controlBase,
                    minHeight: '30px',
                    backgroundColor: 'transparent',
                    borderRadius: 4,
                    boxShadow: 'none',
                    border: checkValidation && !!errors?.dvaCardType ? 'none' : '1px solid #b5b8bd',
                    '&:hover': {
                      borderColor: '#818e9b'
                    },
                    padding: '0 10px'
                  }),
                  valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
                  option: (base, state) => ({
                    ...base,
                    color: '#003A5D',
                    fontWeight: '600',
                    cursor: 'pointer',
                    ...(state.isSelected ? { backgroundColor: '#F2F7F5' } : {}),
                    ...(state.isFocused ? { backgroundColor: '#F2F7F5' } : {}),
                    '&:active': {
                      backgroundColor: '#F2F7F5'
                    }
                  })
                }}
              />
              <ErrorMessage
                error={errors?.dvaCardType || ''}
                visible={(checkValidation && !!errors?.dvaCardType) || false}
              />
            </div>

            <DateInput
              format={DateFormat.MONTH_YEAR}
              label={'DVA expiry date'}
              id="dvaCardExpiryDate"
              value={medicareValue.dvaCardDetails?.expiryDate || ''}
              onChange={(value: string) => {
                handleFieldChange('dvaCardDetails', {
                  ...medicareValue.dvaCardDetails,
                  expiryDate: value
                });
              }}
              checkValidation={checkValidation && !!errors?.dvaCardExpiryDate}
              error={checkValidation ? errors?.dvaCardExpiryDate : ''}
              emptyErrorMessage={checkValidation ? 'Please enter your DVA card expiry date' : ''}
            />
          </div>
        )}

        {checkValidation && (
          <div className={styles.medicareCheck}>
            <VerificationStatus status={medicareErrorMessage} isLoading={isLoading} hideDesc />
          </div>
        )}
      </div>

      <div className={styles.medicareImg}>
        <div className={styles.medicareBox}>
          <div className={`material-icons ${styles.menuIcon}`}>info</div>
          <div className={styles.medicareDescBox}>
            <div className={styles.desc}>How to read your Medicare card</div>
            <img className={styles.medicareImg} src={medicareImg} alt={'Medicare card Intro'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicareForm;
