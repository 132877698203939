import momentTz from 'moment-timezone';
import { MOMENTJS_FORMAT_DATE } from 'components/AvailableTimeSelect/constants';
import {
  GPTimeSlotsWithDateInterface,
  timeSlotWithoutDate
} from 'MumsMatter/utils/hooks/useFetchGPAvailabilityWithTimeZone';

const massageNewTimeSlot = (date: string, timeSlotList?: timeSlotWithoutDate[]) => {
  return timeSlotList
    ?.filter((obj) => obj.isAvailable)
    .map((obj) => ({
      date: date,
      isAvailable: obj.isAvailable,
      startTime: obj.startTime,
      endTime: obj.endTime,
      clinicianId: obj.clinicianId,
      startDateTime: obj.startDateTime,
      endDateTime: obj.endDateTime
    }));
};

export const massageTimeSlot = (timeSlotList: any) => {
  const newTimeSlot = Object.entries(timeSlotList);
  const newMassageSlot = newTimeSlot.map(
    (dateObj: any) =>
      dateObj[1].isAvailable && massageNewTimeSlot(dateObj[0], dateObj[1].timeSlots as timeSlotWithoutDate[])
  );
  return newMassageSlot.filter((el) => Object.keys(el).length !== 0).flat();
};

export const massageTimeSlotObj = (
  timeSlotObj: GPTimeSlotsWithDateInterface,
  clientTimeZone: string
): GPTimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone);
  const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone);

  return {
    isAvailable: timeSlotObj.isAvailable,
    date: clientStartTimeZone.format(MOMENTJS_FORMAT_DATE),
    endDate: clientEndTimeZone.format(MOMENTJS_FORMAT_DATE),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA'),
    startDateTime: timeSlotObj.startDateTime,
    endDateTime: timeSlotObj.endDateTime,
    clinicianId: timeSlotObj.clinicianId
  };
};
