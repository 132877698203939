import styles from './IntakeAddPaymentPage.module.scss';
import AddPayment from './components/AddPayment/AddPayment';
import { useEffect, useState } from 'react';
import { ButtonStatusType } from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import Loading from 'components/Loading/Loading';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';

const IntakeAddPaymentPage = () => {
  const { token } = useGetAccessToken();
  const navigate = useNavigate();

  const { INTAKE, HOME } = useMumsMatterRoutesGenerator();
  const { reserveId, accountId } = useReserveGPAppointmentData();

  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  useEffect(() => {
    if (!token || !reserveId || !accountId) {
      navigate(HOME);
    }
  }, [token, reserveId, accountId, navigate, HOME]);

  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const currentUrl = window.location.origin;

  const onSubmit = async () => {
    setButtonStatus('active');
    try {
      const res = await putProcessWithPaymentMethod(
        accountId || '',
        reserveId || '',
        { cancelUrl: `${currentUrl}${INTAKE.ADD_PAYMENT}`, returnUrl: `${currentUrl}${INTAKE.COMPLETE}` },
        token
      );

      if (res.statusCode === 200) {
        setButtonStatus('finished');
        const responseData = await res.json();
        if (responseData.checkoutUrl) {
          window.location.href = responseData.checkoutUrl;
        }
      } else if (res.statusCode === 204) {
        setButtonStatus('finished');
        navigate(INTAKE.COMPLETE);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to generate the link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | Add payment'}>
      <MumsMatterHeader withPadding noLogin />
      <div className={styles.pageContainer}>
        <MumsMatterContentLayout className={styles.content}>
          {isClientProfileLoading || !clientProfileData ? (
            <Loading />
          ) : (
            <AddPayment
              buttonStatus={buttonStatus}
              name={clientProfileData.firstName || ''}
              onAddPaymentDetails={onSubmit}
            />
          )}
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default IntakeAddPaymentPage;
