import { useAuth0 } from '@auth0/auth0-react';
import { ClientBrand } from 'interfaces/ClientBrand';

export const IS_HELM_APP =
  process.env.REACT_APP_CLIENT_BRAND === ClientBrand.HELM ||
  process.env.REACT_APP_CLIENT_BRAND === ClientBrand.HELM_PARTNER;

export const IS_CORDS_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.CORDS;

export const IS_SOMEONE_HEALTH_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.SOMEONE_HEALTH;

export const IS_CAW_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.CAW;

export const IS_EASE_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.EASE;

export const IS_RECHARGE_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.RECHARGE;

export const IS_SELECT_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.SELECT;

export const IS_PORTOBELLO_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.PORTOBELLO;

export const IS_MUMS_MATTER_APP = process.env.REACT_APP_CLIENT_BRAND === ClientBrand.MUMS_MATTER;

export const useGetClientDetails = () => {
  const { user } = useAuth0();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'] || '';
  const isHelmClient = user?.['https://tacklit.com/isHelm'] || false;
  const isSomeoneHealthClient = user?.['https://tacklit.com/isSomeoneHealth'] || false;
  const isCaWClient = user?.['https://tacklit.com/isCaW'] || false;
  const isEaseClient = user?.['https://tacklit.com/isEase'] || false;
  const isRechargeClient = user?.['https://tacklit.com/isRecharge'] || false;
  const isSelectClient = user?.['https://tacklit.com/isSelect'] || false;
  const isPortobelloClient = user?.['https://tacklit.com/isPortobello'] || false;
  const isMumsMatterClient = user?.['https://tacklit.com/isMumsMatter'] || false;

  return {
    clientRecordId,
    clientProfileId,
    isHelmClient,
    isSomeoneHealthClient,
    isCaWClient,
    isEaseClient,
    isRechargeClient,
    isSelectClient,
    isPortobelloClient,
    isMumsMatterClient
  };
};
