import styles from './SlotPicker.module.scss';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import moment from 'moment';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import CalendarBox from '../CalendarBox/CalendarBox';
import AvailabilityPill from '../AvailabilityPill/AvailabilityPill';

interface SlotPickerProps {
  selectedDate: string;
  slots?: TimeSlotsWithDateInterface[];
  selectedSlots: TimeSlotsWithDateInterface[];
  onClickSlot: (slot: TimeSlotsWithDateInterface) => void;
  isLoading: boolean;
  remainSlots: number;
  className?: string;
}

export const isSlotSelected = ({
  selectedSlots,
  slot
}: {
  selectedSlots: TimeSlotsWithDateInterface[];
  slot: TimeSlotsWithDateInterface;
}) =>
  selectedSlots.some(
    (selectedSlot) =>
      selectedSlot.isAvailable === true &&
      selectedSlot.date === slot.date &&
      selectedSlot.startTime === slot.startTime &&
      selectedSlot.endTime === slot.endTime
  );

const SlotPicker = ({
  selectedDate,
  slots,
  selectedSlots,
  onClickSlot,
  isLoading,
  remainSlots,
  className
}: SlotPickerProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingHeaderWrapper}>
        <Skeleton.Input active className={styles.loadingAvatar} />
        <div className={styles.loadingDescWrapper}>
          <Skeleton.Input active className={styles.loadingTitle} />
          <Skeleton.Input active className={styles.loadingDesc} />
        </div>
      </div>
      <div className={styles.loadingSlotWrapper}>
        {[...Array(9)].map((obj, i) => (
          <Skeleton.Input key={i} active className={styles.loadingSlot} />
        ))}
      </div>
    </div>
  ) : (
    <div className={classNames(styles.container, className)}>
      <CalendarBox
        date={moment(selectedDate)}
        label={moment(selectedDate).format('dddd')}
        desc={(slots?.length || 0) + ' Open Appointments'}
      />
      <div className={styles.timeslotWrapper}>
        {slots?.length ? (
          slots.map((slot, index) => (
            <div
              key={index}
              onClick={() => (isSlotSelected({ selectedSlots, slot }) || remainSlots > 0) && onClickSlot(slot)}
            >
              <AvailabilityPill
                classNames={classNames(
                  styles.slot,
                  isSlotSelected({ selectedSlots, slot }) ? styles.active : remainSlots === 0 && styles.disabled
                )}
                darkYellowPill={false}
              >
                {`${moment(slot.startTime, 'hh:mmA').format('h:mm')} to ${moment(slot.endTime, 'hh:mmA').format(
                  'h:mmA'
                )}`}
              </AvailabilityPill>
            </div>
          ))
        ) : (
          <div>No time slot available for the filter you selected</div>
        )}
      </div>
    </div>
  );
};

export default SlotPicker;
