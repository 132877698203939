import styles from './LoginAvatar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import classNames from 'classnames';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';
import { MumsMatterContactUrl } from 'MumsMatter/utils/env/MumsMatterEnv';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';

interface LoginAvatarProps {
  whiteFont?: boolean;
  withMenu?: boolean;
  loginTextClass?: string;
  whiteMenu?: boolean;
}

const LoginAvatar = ({ whiteFont, withMenu, loginTextClass, whiteMenu }: LoginAvatarProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { initialsName } = useFetchAvatar();

  const { HOME, APPOINTMENT_OVERVIEW, UPDATE_PROFILE } = useMumsMatterRoutesGenerator();

  const menuItems = [
    { name: 'Home', url: HOME, isExternal: false },
    { name: 'Manage my appointments', url: APPOINTMENT_OVERVIEW, isExternal: false },
    // { name: 'Add referral details', url: REFERRAL, isExternal: false },
    { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false },
    { name: 'Contact Us', url: MumsMatterContactUrl, isExternal: true }
  ];

  return (
    <div className={styles.container}>
      {initialsName || isAuthenticated ? (
        <div className={styles.profileWrapper}>
          <div className={styles.profileCard}>
            <div className={styles.profile}>
              <ClientProfileAvatar
                fullAvatarBg
                avatarUrl={user?.picture}
                initialsName={initialsName}
                tokenUser={!!initialsName}
              />
            </div>
          </div>
          {withMenu && isAuthenticated && (
            <div className={whiteMenu ? styles.nameWhite : styles.name}>
              <HeaderMenu menuList={menuItems}>
                <i className={`material-icons ${styles.menuIcon}`}>menu</i>
              </HeaderMenu>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loginWrapper}>
          <div
            onClick={() =>
              loginWithRedirect({
                loginType: 'patient',
                redirectUri: process.env.REACT_APP_CLIENT_DOMAIN_MUMS_MATTER || 'http://localhost:4091', // TODO need to change to prod link
                appState: { returnTo: window.location.pathname }
              })
            }
            className={classNames(loginTextClass, whiteFont ? styles.loginWhite : styles.login)}
          >
            Login
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginAvatar;
