import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum CPSTagTypes {
  ClientRecordsMe = 'ClientRecordsMe',
  EmployerCodes = 'EmployerCodes',
  AttachedClinicianDetails = 'AttachedClinicianDetails',
  AccountPracticeInfo = 'AccountPracticeInfo',
  ClinicianDetails = 'ClinicianDetails',
  ClientRecordTasks = 'ClientRecordTasks',
  ClientMedicare = 'ClientMedicare',
  ReferralInfo = 'ReferralInfo',
  ClientRecordsTags = 'ClientRecordsTags'
}

export const clinicianProfileServicesApiSlice = createApi({
  reducerPath: 'clinicianProfileServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently().catch(() => '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(CPSTagTypes),
  endpoints: () => ({})
});
