import styles from './IntakeConfirmationPage.module.scss';
import Confirmation from './components/Confirmation/Confirmation';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';

const IntakeConfirmationPage = () => {
  return (
    <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Confirm the booking`}>
      <MumsMatterHeader withPadding withMenu />
      <div className={styles.container}>
        <MumsMatterContentLayout className={styles.contentLayoutWrapper}>
          <div className={styles.welcomeMessage}>Intake Call</div>
          <Confirmation />
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default IntakeConfirmationPage;
