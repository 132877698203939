import { MouseEvent as ReactMouseEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';

import Button from 'components/Button/Button';
import ProgressBar from './components/ProgressBar/ProgressBar';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';
import { PatientQuestionFormHeaderProps } from 'components/PatientQuestionForm/PatientQuestionForm';

import styles from './Header.module.scss';

const Header = ({
  completedQuestions,
  totalQuestions,
  isFirstQuestionSet,
  isSkippable,
  onBack,
  onSkip,
  onSaveAndExit
}: PatientQuestionFormHeaderProps) => {
  const { user } = useAuth0();
  const { initialValues } = useFormikContext();
  const { initialsName } = useFetchAvatar();

  const handleBack = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onBack();
  };

  const handleSkip = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSkip(initialValues);
  };

  const handleSaveAndExit = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSaveAndExit();
  };

  return (
    <div className={styles.container} id="header">
      <div className={styles.top}>
        <Button className={styles.button} onClick={handleSaveAndExit}>
          SAVE AND EXIT
          <div className={styles.profilePic}>
            <ClientProfileAvatar
              avatarUrl={user?.picture}
              initialsName={initialsName}
              initialClassName={styles.initial}
            />
          </div>
        </Button>
        {!isFirstQuestionSet && (
          <Button className={styles.button} onClick={handleBack}>
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
            STEP BACK
          </Button>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.progress}>
          <span>
            QUESTION {completedQuestions + 1} / {totalQuestions}
          </span>
          <ProgressBar completedQuestions={completedQuestions} totalQuestions={totalQuestions} />
        </div>
        {isSkippable && (
          <Button className={styles.button} onClick={handleSkip}>
            SKIP
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
