import GPAppointmentTypeSelect from '../GPAppointmentTypeSelect/GPAppointmentTypeSelect';
import styles from './IntakeBooking.module.scss';
import GPTimeSlot from '../GPTimeSlot/GPTimeSlot';
import GPProfile from '../GPProfile/GPProfile';
import SelectedSlotDetails from '../SelectedSlotDetails/SelectedSlotDetails';

import { Skeleton } from 'antd';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { MumsMatterIntakeCallTags } from 'MumsMatter/utils/env/MumsMatterEnv';
import classNames from 'classnames';
import { useEffect } from 'react';
import { usePatchClientRecordsTagsMutation } from 'redux/endpoints/clinicianProfileServices/client';

const IntakeBooking = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const { isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const [patchClientRecordTags] = usePatchClientRecordsTagsMutation();

  useEffect(() => {
    const updateTags = async () => {
      try {
        await patchClientRecordTags({
          body: {
            label: MumsMatterIntakeCallTags.INTAKE_CALL
          }
        });
      } catch (error) {
        console.error('Failed to update client record tags:', error);
      }
    };

    if (!clientProfileData?.mumsMatterCustomisations?.allowIntakeCallBooking) {
      updateTags();
    }
  }, [patchClientRecordTags, clientProfileData?.mumsMatterCustomisations?.allowIntakeCallBooking]);

  return (
    <div className={classNames(styles.container, styles.paddingBottom)}>
      <div className={styles.header}>
        <div className={styles.title}>Book a Intake call appointment with us</div>
        <div className={styles.description}>
          We make getting a GP referral easy.
          <br />
          <br />
          We have a team of friendly GPs available for video consultations to assess your suitability for a Mental
          Health Treatment Plan (MHTP) and provide you with the necessary referral.
          <br />
          <br />
          Please select below whether you are after your Initial MHTP or if you already have a MTHP and are needing a GP
          Review. You’ll need to attend a GP Review appointment after 6 sessions with your psychologist.
        </div>
      </div>
      <div className={styles.content}>
        <GPAppointmentTypeSelect />
        {isClientProfileLoading || isAttachedClinicianDetailLoading ? (
          <div className={styles.loadingWrapper}>
            <Skeleton active />
          </div>
        ) : (
          <>
            <GPTimeSlot />
            <div className={styles.gpSlotDetailsWrapper}>
              <GPProfile />
              <SelectedSlotDetails />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IntakeBooking;
