/* eslint-disable react/no-multi-comp */
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, MouseEvent as ReactMouseEvent } from 'react';

import { useLogout } from 'utils/hooks/logout';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import ProgressBar from '../Header/components/ProgressBar/ProgressBar';
import Button from 'components/v2/Button/Button';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';

import styles from './Gateway.module.scss';
import { useNavigate } from 'react-router-dom';
import { ClientBrand, CLIENT_BRAND, CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import classnames from 'classnames';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_MUMS_MATTER_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import ButtonSH from '../../../../SomeoneHealth/components/ButtonSH/ButtonSH';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import ButtonPortobello from 'Portobello/components/ButtonPortobello/ButtonPortobello';
import ButtonMumsMatter from '../../../../MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';

interface GatewayProps {
  state: 'done' | 'saveAndExit' | 'welcomeBack' | 'welcomeSurvey';
  onReturnToOnboardingQuestions: () => void;
  onReturnToBackground?: () => void;
  assessment?: FirstAssessment;
  practiceName?: string;
  clinicianName?: string;
  surveyNote?: string;
  isFacilitated?: boolean;
}

const Gateway = ({
  state,
  onReturnToOnboardingQuestions,
  onReturnToBackground,
  assessment,
  practiceName,
  clinicianName,
  surveyNote,
  isFacilitated
}: GatewayProps) => {
  const { user } = useAuth0();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { initialsName } = useFetchAvatar();

  const handleReturnToOnboardingQuestions = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onReturnToOnboardingQuestions();
  };

  const handleReturnToBackground = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onReturnToBackground && onReturnToBackground();
  };

  const handleHomeBtn = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate('/');
  };

  const { completedQuestions, totalQuestions } = useMemo(() => {
    const { clinicalAssessments = [] } = assessment?.assessment || {};
    const onboardingQuestions = assessment?.assessment.onboardingQuestions.assessmentField || [];
    let completedQuestions = 0;
    let totalQuestions = clinicalAssessments.length;

    for (let i = 0; i < clinicalAssessments.length; i++) {
      if (clinicalAssessments[i].sections[0]?.questions[0]?.response) {
        completedQuestions++;
      }
    }

    for (let i = 0; i < onboardingQuestions.length; i++) {
      const currentOnboardinQuestionSet = onboardingQuestions[i];

      for (let j = 0; j < currentOnboardinQuestionSet.sections.length; j++) {
        const currentOnboardingQuestionSection = currentOnboardinQuestionSet.sections[j];

        for (let k = 0; k < currentOnboardingQuestionSection.questions.length; k++) {
          totalQuestions++;

          // eslint-disable-next-line max-depth
          if (currentOnboardingQuestionSection.questions[k].response) {
            completedQuestions++;
          }
        }
      }
    }

    return { completedQuestions, totalQuestions: totalQuestions === 0 ? undefined : totalQuestions };
  }, [assessment]);

  const DoneCard = () => (
    <div className={styles.card}>
      <div className={styles.doneContent}>
        <div className={styles.successWrapper}>
          <span className={`material-icons ${styles.checkIcon}`}>done</span>
          <span className={styles.doneText}>All {totalQuestions} questions answered.</span>
        </div>
        {user && (
          <div className={styles.homePageBtn}>
            {IS_MUMS_MATTER_APP ? (
              <ButtonMumsMatter onClick={handleHomeBtn}>Back to home page</ButtonMumsMatter>
            ) : (
              <Button onClick={handleHomeBtn}>Back to home page</Button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const ReturnCard = () => (
    <div className={styles.card}>
      <div className={styles.returnContent}>
        <div className={styles.progress}>
          <span>
            QUESTION {completedQuestions} / {totalQuestions}
          </span>
          <ProgressBar completedQuestions={completedQuestions} totalQuestions={totalQuestions} />
        </div>
        {IS_SOMEONE_HEALTH_APP ? (
          <ButtonSH className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonSH>
        ) : IS_EASE_APP ? (
          <ButtonEase className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonEase>
        ) : IS_RECHARGE_APP ? (
          <ButtonRecharge className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonRecharge>
        ) : IS_SELECT_APP ? (
          <ButtonSelect className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonSelect>
        ) : IS_PORTOBELLO_APP ? (
          <ButtonPortobello className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonPortobello>
        ) : IS_MUMS_MATTER_APP ? (
          <ButtonMumsMatter className={styles.button} onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </ButtonMumsMatter>
        ) : (
          <Button className={styles.button} variant="primary" onClick={handleReturnToOnboardingQuestions}>
            Return to survey
          </Button>
        )}
      </div>
    </div>
  );

  const BackgroundCard = () => (
    <div className={styles.card}>
      <div className={styles.returnContent}>
        {IS_SOMEONE_HEALTH_APP ? (
          <ButtonSH className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonSH>
        ) : IS_EASE_APP ? (
          <ButtonEase className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonEase>
        ) : IS_RECHARGE_APP ? (
          <ButtonRecharge className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonRecharge>
        ) : IS_SELECT_APP ? (
          <ButtonSelect className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonSelect>
        ) : IS_PORTOBELLO_APP ? (
          <ButtonPortobello className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonPortobello>
        ) : IS_MUMS_MATTER_APP ? (
          <ButtonMumsMatter className={styles.button} onClick={handleReturnToBackground}>
            Start survey
          </ButtonMumsMatter>
        ) : (
          <Button className={styles.button} variant="primary" onClick={handleReturnToBackground}>
            Start survey
          </Button>
        )}
      </div>
    </div>
  );

  const copy = {
    done: {
      title: `You've done it.`,
      content: (
        <p>
          Well done, you have completed the onboarding questionnaire for{' '}
          <strong>{practiceName || clinicianName}</strong>.
          {CLIENT_BRAND !== ClientBrand.HELM
            ? ' They will now review your information in preparation for seeing you. They will connect with you in due course.'
            : ''}
        </p>
      ),
      card: <DoneCard />
    },
    saveAndExit: {
      title: `Great progress.`,
      content: (
        <>
          <p>You have taken the first steps, well done.</p>
          <p>We have saved your answers thus far, you can return and complete whenever you like.</p>
        </>
      ),
      card: <ReturnCard />
    },
    welcomeBack: {
      title: `Welcome back.`,
      content: surveyNote ? (
        <>
          <div className={`ql-editor ${styles.text}`} dangerouslySetInnerHTML={{ __html: surveyNote || '' }} />
        </>
      ) : (
        <>
          <p>
            Please continue with the onboarding survey <strong>{clinicianName}</strong> asked you to complete.
          </p>
          <p>We have saved your answers thus far, you can return and complete whenever you like.</p>
        </>
      ),
      card: <ReturnCard />
    },
    welcomeSurvey: {
      title: 'Welcome.',
      content: surveyNote ? (
        <>
          <div className={`ql-editor ${styles.text}`} dangerouslySetInnerHTML={{ __html: surveyNote || '' }} />
        </>
      ) : (
        <>
          <p>
            Please continue with the onboarding survey <strong>{clinicianName}</strong> asked you to complete.
          </p>
          <p>We have saved your answers thus far, you can return and complete whenever you like.</p>
        </>
      ),
      card: <BackgroundCard />
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme',
        IS_CAW_APP && 'caw-theme',
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme',
        IS_PORTOBELLO_APP && 'portobello-theme',
        IS_MUMS_MATTER_APP && 'mums-matter-theme'
      )}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <div className={styles.profilePic}>
            <ClientProfileAvatar avatarUrl={user?.picture} initialsName={initialsName} tokenUser />
          </div>
          {!isFacilitated && (
            <div className={styles.logout} onClick={logout}>
              Log out<span className="material-icons">exit_to_app</span>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <span className={styles.title}>{copy[state].title}</span>
          {copy[state].content}
          {!surveyNote ||
            (state !== 'welcomeSurvey' && state !== 'welcomeBack' && (
              <span className={styles.signature}>Team {IS_HELM_APP ? 'HELM' : CLIENT_BRAND_LABEL[CLIENT_BRAND]}</span>
            ))}
        </div>
      </div>
      {copy[state].card}
    </div>
  );
};

export default Gateway;
