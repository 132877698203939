import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';

export const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    putReferralDetail: builder.mutation<
      ReferralDetailUpdateInterface,
      { body: ReferralDetailUpdateInterface; authToken?: string }
    >({
      query: ({ body, authToken }) => {
        return {
          url: '/client-records/me/referral',
          method: 'PUT',
          body,
          headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
        };
      },
      invalidatesTags: [CPSTagTypes.ReferralInfo]
    })
  })
});

export const { usePutReferralDetailMutation } = clientRecordsApiSlice;
