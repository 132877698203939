import styles from './GPProfile.module.scss';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';
import classNames from 'classnames';
import { useAppSelector } from 'redux/hooks';
import { selectSelectedTimeSlot } from 'redux/globalStore/gpBookingSlice';
import { useGetAccountClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { Skeleton } from 'antd';

const GPProfile = () => {
  const selectedTimeSlot = useAppSelector(selectSelectedTimeSlot);

  const {
    data: gpDetails,
    isLoading,
    isFetching
  } = useGetAccountClinicianDetailsByIdQuery(
    { slugUrl: 'someone-health', clinicianId: selectedTimeSlot?.clinicianId || '' },
    { skip: !selectedTimeSlot?.clinicianId }
  );

  return selectedTimeSlot ? (
    <div className={styles.container}>
      <div className={styles.gpProfileContainer}>
        <div className={styles.title}>GP Profile</div>
        {(isLoading || isFetching) && <Skeleton active />}
        {!isLoading && !isFetching && gpDetails && (
          <div className={styles.gpProfileContent}>
            {/* Profile */}
            <div className={styles.item}>
              <div className={styles.avatarContainer}>
                <img className={styles.image} src={gpDetails.avatar} alt={'GP avatar'} />
                <div className={styles.nameContainer}>
                  <div className={styles.salutation}>{gpDetails.title}</div>
                  <div className={styles.name}>{gpDetails.name}</div>
                </div>
              </div>
            </div>

            {/* Bona fides */}
            <div className={styles.item}>
              <div className={styles.label}>BONA FIDES</div>
              <div className={styles.bonaFideDetails}>
                {gpDetails.helmControl?.bonaFides?.yearsOfExperience?.active && (
                  <div className={styles.details}>
                    <i className={`material-icons ${styles.icon}`}>work</i>
                    <div>{gpDetails.helmControl.bonaFides.yearsOfExperience.value} Years Experience</div>
                  </div>
                )}
                {gpDetails.helmControl?.bonaFides?.languages?.active && (
                  <div className={styles.details}>
                    <i className={`material-icons ${styles.icon}`}>language</i>
                    <div>
                      {gpDetails.helmControl.bonaFides.languages.value
                        ?.map((obj) => LANGUAGE_COLLECTION[obj as keyof typeof LANGUAGE_COLLECTION])
                        .join(' | ')}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Credentials */}
            {gpDetails.helmControl?.credentials && (
              <div className={classNames(styles.item, styles.credentials)}>
                <div className={styles.label}>CREDENTIALS</div>
                <p>{gpDetails.helmControl.credentials}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default GPProfile;
