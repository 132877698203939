/* eslint-disable no-constant-condition */
import classnames from 'classnames';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { generatePath, Link } from 'react-router-dom';
import PsychologistAvatar from 'Recharge/components/PsychologistAvatar/PsychologistAvatar';
import PsychologistBonaFides from 'Recharge/components/PsychologistBonaFides/PsychologistBonaFides';
import { MentalHealthList, MENTAL_HEALTH_LIST_LABELS } from 'Recharge/interfaces/mentalHealth';
import { PRONOUN_OPTIONS } from 'Recharge/pages/PsychologistListing/components/PsychologistBasicDetails/PsychologistBasicDetails';
import PsychologistSpecialisation from 'Recharge/pages/PsychologistListing/components/PsychologistSpecialisation/PsychologistSpecialisation';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import styles from './MyPsychologistDetail.module.scss';
import { Modal } from 'antd';
import { useState } from 'react';
import ConfirmationDisconnectModal from './components/ConfirmationDisconnectModal/ConfirmationDisconnectModal';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

interface MyPsychologistDetailProps {
  classNames?: string;
}
const MyPsychologistDetail = ({ classNames }: MyPsychologistDetailProps) => {
  const dispatch = useDispatch();
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const clinician = attachedClinicianDetail?.clinician;

  const detailsPagePath =
    clinician &&
    generatePath(PRACTITIONER.DETAILS, {
      clinicianId: clinician.slugUrl
    });
  const [showDisconnect, setShowDisconnect] = useState<boolean>();

  const handleCompleteDisconnect = () => {
    setShowDisconnect(false);
    dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.AttachedClinicianDetails]));
  };

  return (
    <div className={classnames(styles.container, classNames)}>
      {isAttachedClinicianDetailLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={classnames(styles.content, classNames)}>
            <div className={styles.header}>
              <div className={styles.title}>My Psychologist</div>
              {clinician && (
                <ButtonRecharge variant={'outlined'} onClick={() => setShowDisconnect(!showDisconnect)}>
                  Find a different psychologist to work with
                </ButtonRecharge>
              )}
            </div>

            {clinician ? (
              <div className={styles.psychologistInfo}>
                <div className={styles.overview}>
                  <div className={styles.detailsWrapper}>
                    <PsychologistAvatar
                      classNameImage={styles.profileImg}
                      profileImg={clinician.avatar}
                      detailPath={detailsPagePath || ''}
                    />
                    <Link to={detailsPagePath || ''}>
                      <ButtonRecharge className={styles.scheduleButton}>Schedule Appointment</ButtonRecharge>
                    </Link>
                  </div>
                  <div className={styles.bonaFidesWrapper}>
                    <PsychologistBonaFides
                      limitDisplayQualifications
                      bonaFidesDetails={clinician.helmControl.bonaFides}
                    />
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.greet}>Hi, I'm {clinician.name}</div>
                  <div className={styles.descWrapper}>
                    <div className={styles.pronouns}>
                      {clinician.pronouns
                        ? `${PRONOUN_OPTIONS[clinician.pronouns as keyof typeof PRONOUN_OPTIONS]}`
                        : ''}
                    </div>
                    {clinician.helmControl.shortDescription && (
                      <div className={styles.desc}>
                        <div className={styles.label}>INTRODUCTION</div>
                        <div
                          className={styles.contentDesc}
                          dangerouslySetInnerHTML={{
                            __html: clinician.helmControl.shortDescription
                          }}
                        />
                      </div>
                    )}
                    {clinician.helmControl.primarySpecialisations &&
                      clinician.helmControl.primarySpecialisations.length > 0 && (
                        <div className={styles.desc}>
                          <PsychologistSpecialisation
                            label={'MAIN AREAS OF PRACTICE'}
                            specialisationsDetails={clinician.helmControl.primarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                          />
                        </div>
                      )}
                    {clinician.helmControl.secondarySpecialisations &&
                      clinician.helmControl.secondarySpecialisations.length > 0 && (
                        <div className={styles.desc}>
                          <PsychologistSpecialisation
                            label={'I CAN ALSO HELP WITH'}
                            specialisationsDetails={clinician.helmControl.secondarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                            badgeClassName={styles.secondaryAreaBadge}
                          />
                        </div>
                      )}
                  </div>
                </div>
                <Modal
                  width={700}
                  visible={showDisconnect}
                  onCancel={() => {
                    setShowDisconnect(false);
                  }}
                  bodyStyle={{ top: 50 }}
                  footer={null}
                >
                  <ConfirmationDisconnectModal clinician={clinician} onSubmitted={handleCompleteDisconnect} />
                </Modal>
              </div>
            ) : (
              <>
                <div className={styles.psychologistInfo}>
                  <div className={styles.avatar}>
                    <i className={`material-icons ${styles.icon}`}>person</i>
                  </div>
                  <div className={styles.detail}>
                    <div className={styles.headerDesc}>Please select a psychologist</div>
                    <div className={styles.subDesc}>
                      Find the right psychologist for you from panel of experience, verified providers
                    </div>
                    <Link to={generatePath(PRACTITIONER.LISTING)}>
                      <ButtonRecharge className={styles.findButton}>Find the right psychologist for me</ButtonRecharge>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyPsychologistDetail;
