import styles from './NoIntakeCall.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useNavigate } from 'react-router-dom';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import ButtonMumsMatter from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import { usePatchClientRecordsTagsMutation } from 'redux/endpoints/clinicianProfileServices/client';
import { useEffect } from 'react';
import { MumsMatterIntakeCallTags } from 'MumsMatter/utils/env/MumsMatterEnv';

const NoIntakeCall = () => {
  const navigate = useNavigate();
  const [patchClientRecordTags] = usePatchClientRecordsTagsMutation();
  const { HOME } = useMumsMatterRoutesGenerator();

  useEffect(() => {
    const updateTags = async () => {
      try {
        await patchClientRecordTags({
          body: {
            label: MumsMatterIntakeCallTags.NO_INTAKE_CALL
          }
        });
      } catch (error) {
        console.error('Failed to update client record tags:', error);
      }
    };

    updateTags();
  }, [patchClientRecordTags]);

  return (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | No Intake Call'}>
      <MumsMatterHeader withPadding className={styles.header} />
      <div className={styles.containerWrapper}>
        <MumsMatterContentLayout className="mums-matter-theme">
          <div className={styles.container}>
            <div className={styles.title}>BASED ON WHAT YOU'VE TOLD US</div>
            <div className={styles.infoContainer}>
              <div className={styles.desc}>
                Based on the information you have provided, we thinking there might be a better service for you.
                <br />
                We will be in touch with you shortly to discuss your options.
              </div>
            </div>
            <div className={styles.btnWrapper}>
              <ButtonMumsMatter
                onClick={() => {
                  navigate(HOME);
                }}
              >
                Back to Home
              </ButtonMumsMatter>
            </div>
          </div>
        </MumsMatterContentLayout>
        <TacklitFooter darkFooter />
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default NoIntakeCall;
