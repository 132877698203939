import { ReactNode, useCallback, useState } from 'react';
import styles from './MumsMatterCreateProfileForm.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import classnames from 'classnames';
import classNames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import ButtonMumsMatter, { ButtonStatusType } from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';
import { initialCustomer, MumsMatterSignUpErrorInterface, validationSignUpForm } from './constants';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { scrollErrorsIntoView, scrollToView } from 'utils/scrollToView';
import MobilePhoneInput from 'components/Field/MobilePhoneInput/MobilePhoneInput';
import DateInput, { DateFormat } from 'components/Field/DateInput/DateInput';
import Input from 'components/Input/Input';
import {
  EngageSignUpPayload,
  postPatientSignupWithoutInvitation
} from 'utils/http/PatientProfileService/Patient/patient';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import ErrorMessageWithHelpLink from 'MumsMatter/pages/SignUp/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { config } from 'config/config';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { storeUserSession } from 'MumsMatter/pages/SignUp/helper/userSession';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import { MumsMatterAccountId, MumsMatterPrivacyPolicyUrl } from 'MumsMatter/utils/env/MumsMatterEnv';

const MumsMatterCreateProfileForm = () => {
  const [pwScore, setPwScore] = useState(0);
  const [isPrivacyPolicyAgreeChecked, setIsPrivacyPolicyAgreeChecked] = useState(false);
  const [showPrivacyPolicyError, setShowPrivacyPolicyError] = useState(false);
  const [showPwError, setShowPwError] = useState(false);
  const [values, setValues] = useState<MumsMatterSignUpErrorInterface>(initialCustomer);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [errors, setErrors] = useState<MumsMatterSignUpErrorInterface>();
  const [receiveSMS, setReceiveSMS] = useState(true);
  const [receiveEmail, setReceiveEmail] = useState(true);
  const navigate = useNavigate();
  const { SIGNUP } = useMumsMatterRoutesGenerator();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  const validateField = useCallback((newVal: MumsMatterSignUpErrorInterface) => {
    const { validationErrors, cleanValidationError } = validationSignUpForm(newVal);
    setErrors(validationErrors);
    return cleanValidationError;
  }, []);

  const handleFieldChange = useCallback(
    (fieldName: keyof MumsMatterSignUpErrorInterface, value: string) => {
      const updatedField = {
        ...values,
        [fieldName]: value
      };
      validateField(updatedField);
      setValues(updatedField);
    },
    [validateField, values]
  );

  const handleCreateSecureAccount = async () => {
    setShowPwError(true);
    setCheckValidation(true);
    setShowPrivacyPolicyError(true);

    const validate = validateField(values);
    scrollErrorsIntoView(validate);
    const checkFieldHaveError = Object.values(validate).some((value) => value !== '');

    if (
      !checkFieldHaveError &&
      !mobileNumberErrorMsg &&
      isPrivacyPolicyAgreeChecked &&
      pwScore === 4 &&
      values.password
    ) {
      setButtonStatus('active');
      const [phoneValidate, checkEmailExisted] = await Promise.all([
        validatePhoneNumber(values.mobileNumber),
        checkEmailAlreadyExisted({
          accountId: MumsMatterAccountId,
          email: values?.email || ''
        })
      ]);

      if (phoneValidate.valid && checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, dateOfBirth } = values;

          const payloadMassage: EngageSignUpPayload = {
            clinicianId: '',
            clientRecord: {
              avatar: '',
              mobile: values.mobileNumber,
              email,
              name: firstName,
              password,
              dateOfBirth,
              firstName,
              lastName,
              postcode: '',
              timeZone: config.defaultTimezone,
              communicationPreference:
                receiveSMS && receiveEmail
                  ? CommunicationPreference.NoPreference
                  : receiveSMS
                  ? CommunicationPreference.SMS
                  : receiveEmail
                  ? CommunicationPreference.Email
                  : CommunicationPreference.NONE
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(MumsMatterAccountId, payloadMassage);

          const { clientRecord, authToken } = await callPatientSignUp.json();
          setButtonStatus('finished');
          storeUserSession({ clientRecord, authToken }, dateOfBirth);
          scrollToView('MumsMatterHeader');
          navigate(SIGNUP.INFO_SETUP);
        } catch (ex) {
          setButtonStatus('');
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        } finally {
          setTimeout(() => {
            setButtonStatus('');
          }, 1000);
        }
      }

      if (!checkEmailExisted || !checkEmailExisted.response || checkEmailExisted.response.used === true) {
        setButtonStatus('');
        scrollErrorsIntoView({ email: 'Email address is already used' });
        setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." />);
      }

      if (!phoneValidate.valid) {
        setButtonStatus('');
        scrollErrorsIntoView({ mobileNumber: 'Mobile number not recognized.' });
        setMobileNumberErrorMsg(
          <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
        );
      }
    }
  };

  const handleTogglePrivacyPolicyAgreeChecked = (value: boolean) => {
    setIsPrivacyPolicyAgreeChecked(value);
    setShowPrivacyPolicyError(true);
  };

  return (
    <div className={classNames(styles.container, 'mums-matter-theme')}>
      <div>
        <div className={styles.basicFormContainer}>
          <div className={styles.nameWrapper}>
            <Input
              id="firstName"
              inputClass={styles.customInput}
              className={styles.nameInput}
              label="First Name*"
              name="firstName"
              required
              labelClass={styles.inputLabel}
              onChange={(e) => {
                handleFieldChange('firstName', e.target.value);
              }}
              externalError={checkValidation ? errors?.firstName : ''}
            />
            <Input
              id="lastName"
              inputClass={styles.customInput}
              className={styles.nameInput}
              label="Last Name*"
              name="lastName"
              required
              labelClass={styles.inputLabel}
              onChange={(e) => {
                handleFieldChange('lastName', e.target.value);
              }}
              externalError={checkValidation ? errors?.lastName : ''}
            />
          </div>
          <div className={styles.dobInputWrapper}>
            <DateInput
              id={'dateOfBirth'}
              label={'Date of Birth*'}
              value={values.dateOfBirth || ''}
              format={DateFormat.DAY_MONTH_YEAR}
              onChange={(value) => {
                handleFieldChange('dateOfBirth', value);
              }}
              error={checkValidation ? errors?.dateOfBirth : ''}
              checkValidation={checkValidation}
              emptyErrorMessage={'Please enter your date of birth'}
            />
          </div>
          <div className={styles.phoneInputWrapper}>
            <MobilePhoneInput
              id={'mobileNumber'}
              label={'Mobile Phone Number*'}
              value={values.mobileNumber || ''}
              onChange={(value) => {
                handleFieldChange('mobileNumber', value);
                setMobileNumberErrorMsg('');
              }}
              onError={setMobileNumberErrorMsg}
              error={checkValidation ? errors?.mobileNumber || mobileNumberErrorMsg : ''}
              checkValidation={checkValidation}
            />
          </div>
          <Input
            id="email"
            inputClass={styles.customInput}
            className={styles.emailInput}
            label="Email address*"
            name="email"
            required
            onChange={(e) => {
              handleFieldChange('email', e.target.value);
              setEmailErrorMsg('');
            }}
            labelClass={styles.inputLabel}
            externalError={checkValidation ? errors?.email || emailErrorMsg : ''}
          />
        </div>
        <div className={styles.createAccountContainer}>
          <div className={styles.createAccountHeader}>
            <div className={styles.createAccountTitle}>Create secure account</div>
          </div>
          <Input
            id="password"
            inputClass={styles.customInput}
            className={styles.passwordInput}
            label="Set Secure Password*"
            name="password"
            type="password"
            required
            value={values.password}
            externalError={
              checkValidation && showPwError ? errors?.password || (pwScore < 4 && 'This password is too weak') : ''
            }
            labelClass={styles.inputLabel}
            allowShowPassword
            showPasswordButtonClass={styles.showPasswordButtonClass}
            onChange={(e) => {
              handleFieldChange('password', e.target.value);
            }}
          />
          <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
            It must meet the following password rules:
            <div className={styles.hints}>
              <PasswordRules
                password={values.password || ''}
                onChangePasswordScore={(value) => {
                  setPwScore(value);
                }}
              />
            </div>
          </div>
          <div className={styles.communicationPreferences}>
            <div className={styles.title}>Communicating with you*</div>
            <div className={styles.desc}>
              We will send you relevant information regarding your appointment via email and SMS. This includes items
              like appointment reminders, and requests to complete documents relevant to your program. This is not
              marketing.
            </div>
            <div className={styles.checkBoxes}>
              <div className={styles.checkBoxText}>
                <CheckBox
                  id="receiveEmail"
                  value={receiveEmail}
                  onChange={(e) => setReceiveEmail(e.target.checked)}
                  className={styles.checkBox}
                  label="I am happy to receive emails from the team"
                />
              </div>
              <div className={styles.checkBoxText}>
                <CheckBox
                  id="receiveSMS"
                  value={receiveSMS}
                  onChange={(e) => setReceiveSMS(e.target.checked)}
                  className={styles.checkBox}
                  label="I am happy to receive SMS from the team"
                />
              </div>
            </div>
          </div>
          <div className={styles.privacyPolicyCheckboxContainer}>
            <div className={styles.title}>Managing your information*</div>
            <div className={styles.desc}>
              We process your information securely in line with our{' '}
              <a className={styles.link} target={'_blank'} href={MumsMatterPrivacyPolicyUrl} rel={'noreferrer'}>
                privacy policy
              </a>
            </div>
            <div className={styles.checkBoxText}>
              <CheckBox
                id="isPrivacyPolicyAgreeChecked"
                value={isPrivacyPolicyAgreeChecked}
                onChange={(e) => handleTogglePrivacyPolicyAgreeChecked(e.target.checked)}
                className={styles.checkBox}
                label={`I agree to Mums Matter Psychology privacy policy`}
              />
            </div>
            <ErrorMessage
              error="Please agree to the Privacy Policy"
              visible={showPrivacyPolicyError && !isPrivacyPolicyAgreeChecked}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <ButtonMumsMatter
              containerClassName={styles.submitButtonWrapper}
              className={styles.submitButton}
              status={buttonStatus}
              type="submit"
              onClick={() => {
                handleCreateSecureAccount();
              }}
            >
              Create secure account
            </ButtonMumsMatter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MumsMatterCreateProfileForm;
