import classNames from 'classnames';

import styles from './MumsMatterContentLayout.module.scss';

interface MumsMatterContentLayoutProps {
  children?: any;
  className?: string;
}

const MumsMatterContentLayout = ({ children, className }: MumsMatterContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default MumsMatterContentLayout;
