import { Route, Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import { UserContextProvider } from 'utils/UserContextProvider';
import MumsMatterLoginWrapper from 'MumsMatter/components/Layout/MumsMatterLoginWrapper/MumsMatterLoginWrapper';
import MumsMatterWrapper from 'MumsMatter/components/Layout/MumsMatterWrapper/MumsMatterWrapper';
import SignUp from 'MumsMatter/pages/SignUp/SignUp';
import PsychometricList from 'pages/Psychometric/PsychometricList/PsychometricList';
import PatientAdHocAssessment from 'pages/PatientAdHocAssessment/PatientAdHocAssessment';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import InfoSetup from 'MumsMatter/pages/InfoSetup/InfoSetup';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import Welcome from 'MumsMatter/pages/Welcome/Welcome';
import MumsMatterHomePage from 'MumsMatter/pages/MumsMatterHomePage/MumsMatterHomePage';
import UpdateProfile from 'MumsMatter/pages/UpdateProfile/UpdateProfile';
import AppointmentsOverview from 'MumsMatter/pages/AppointmentsOverview/AppointmentsOverview';
import PatientCheckIn from 'pages/PatientCheckIn/PatientCheckIn';
import Onboarding from 'pages/OnboardingAssessment/Onboarding/Onboarding';
import SurveyListWrapper from 'MumsMatter/pages/Survey/SurveyListWrapper/SurveyListWrapper';
import SurveyQuestionWrapper from 'MumsMatter/pages/Survey/SurveyQuestionWrapper/SurveyQuestionWrapper';
import ConsentListWrapper from 'MumsMatter/pages/ConsentListWrapper/ConsentListWrapper';
import ConsentFormSignUp from 'MumsMatter/pages/ConsentFormSignUp/ConsentFormSignUp';
import FormsPage from 'MumsMatter/pages/FormsPage/FormsPage';
import IntakeBookingPage from 'MumsMatter/pages/IntakeBookingPage/IntakeBookingPage';
import NoIntakeCall from 'MumsMatter/pages/NoIntakeCall/NoIntakeCall';
import IntakeConfirmationPage from 'MumsMatter/pages/IntakeConfirmationPage/IntakeConfirmationPage';
import IntakeSuccessPage from 'MumsMatter/pages/IntakeSuccessPage/IntakeSuccessPage';
import IntakeAddPaymentPage from 'MumsMatter/pages/IntakeAddPaymentPage/IntakeAddPaymentPage';

const MumsMatterRoutes = () => {
  const {
    SIGNUP,
    // AVATAR,
    HOME,
    // PENDING_ACTIONS,
    UPDATE_PROFILE,
    // MY_PSYCHOLOGIST,
    // REFERRAL,
    APPOINTMENT_OVERVIEW,
    ONBOARDING,
    SURVEY,
    PSYCHOMETRIC,
    CHECKIN,
    CONSENT,
    // INVOICE,
    // REPORT,
    // LETTER,
    FORM,
    INTAKE
  } = useMumsMatterRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: MumsMatterWrapper })}
        {appRoute({
          path: SIGNUP.INFO_SETUP,
          component: InfoSetup,
          layout: (props) => <MumsMatterWrapper blueBg {...props} />
        })}
        {appRoute({
          path: SIGNUP.WELCOME,
          component: Welcome,
          layout: (props) => <MumsMatterWrapper greenBg {...props} />
        })}

        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: MumsMatterLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: MumsMatterLoginWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: SurveyListWrapper,
          layout: MumsMatterLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: SurveyQuestionWrapper,
          layout: MumsMatterLoginWrapper
        })}

        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: HOME, component: MumsMatterHomePage, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: MumsMatterLoginWrapper })}
          {appRoute({
            path: APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: MumsMatterLoginWrapper
          })}

          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: FORM.DETAILS, component: FormsPage, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: INTAKE.BASE, component: IntakeBookingPage, layout: MumsMatterLoginWrapper })}
          {appRoute({
            path: INTAKE.CONFIRM_BOOKING,
            component: IntakeConfirmationPage,
            layout: MumsMatterLoginWrapper
          })}
          {appRoute({ path: INTAKE.ADD_PAYMENT, component: IntakeAddPaymentPage, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: INTAKE.COMPLETE, component: IntakeSuccessPage, layout: MumsMatterLoginWrapper })}
          {appRoute({ path: INTAKE.NO_INTAKE_CALL, component: NoIntakeCall, layout: MumsMatterLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default MumsMatterRoutes;
