import { DeliveryType, OtherInstructions, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import { BookingRuleType } from '../clientRecords';

interface ReserveAppointmentCookies {
  reserveId?: string;
  clinicianId?: string;
  accountId?: string;
  theme?: string;
  sid?: string;
  appointmentTypeInfo?: {
    name: string;
    description: string;
    rate: number;
    isAdvisory: boolean;
    deliveryType: DeliveryType;
    otherInstructions?: OtherInstructions;
    timeSlot: TimeSlotsWithDateInterface[];
    sessionTypeId: string;
  };
  supportStaffProfile?: {
    name: string;
    avatar: string;
  };
  programId?: string;
  psychologistName?: string;
  gpDetails?: {
    appointmentTypeName?: string;
    deliveryType?: string;
    gpName?: string;
  };
  isGPAppointmentSelected?: boolean;
  clientBookingRule?: BookingRuleType;
}

export const reservedAppointmentName = 'reservedSHAppointmentData';
export const reservedPartnerReferral = 'reservedSHPartnerAppointmentData';

export const reservedHelmPartnerReferral = 'reservedHelmPartnerReferralAppointmentData';

export const useReserveAppointmentData = (reservedAppointmentId?: string) => {
  const data: ReserveAppointmentCookies | Record<string | number, never> = JSON.parse(
    localStorage.getItem(reservedAppointmentId || reservedAppointmentName) || '{}'
  );

  return data;
};

export const useSetReserveAppointmentData = (reservedAppointmentId?: string) => {
  const reservedId = reservedAppointmentId || reservedAppointmentName;
  const setReserveAppointmentData = (reserveAppointment: ReserveAppointmentCookies) =>
    localStorage.setItem(reservedId, JSON.stringify(reserveAppointment));
  const removeReserveAppointmentData = () => localStorage.removeItem(reservedId);
  return { setReserveAppointmentData, removeReserveAppointmentData };
};

// GP flow

interface ReserveGPAppointmentCookies {
  reserveId?: string;
  clinicianId?: string;
  accountId?: string;
}

export const LOCAL_STORAGE_RESERVED_GP_APPOINTMENT_KEY = 'reservedGPApointment';

export const useReserveGPAppointmentData = () => {
  const data: ReserveGPAppointmentCookies | Record<string | number, never> = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_RESERVED_GP_APPOINTMENT_KEY) || '{}'
  );

  return data;
};

export const useSetReserveGPAppointmentData = () => {
  const setReserveGPAppointmentData = (reserveAppointment: ReserveGPAppointmentCookies) =>
    localStorage.setItem(LOCAL_STORAGE_RESERVED_GP_APPOINTMENT_KEY, JSON.stringify(reserveAppointment));
  const removeReserveGPAppointmentData = () => localStorage.removeItem(LOCAL_STORAGE_RESERVED_GP_APPOINTMENT_KEY);
  return { setReserveGPAppointmentData, removeReserveGPAppointmentData };
};
