import { Helmet, HelmetProvider } from 'react-helmet-async';

interface MumsMatterHelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favMumsMatter.png`;

const MumsMatterHelmetWrapper = ({ children, title }: MumsMatterHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'Mums Matter Psychology offers psychologist sessions at Brimbank City Council Maternal & Child Health Centres including St Albans, Sunshine and Keilor Downs.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default MumsMatterHelmetWrapper;
