import styles from './Welcome.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useNavigate } from 'react-router-dom';
import {
  MumsMatterPrivacyPolicyUrl,
  MumsMatterSupportEmail,
  MumsMatterSupportMobile
} from 'MumsMatter/utils/env/MumsMatterEnv';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import { useMumsMatterRoutesGenerator } from 'MumsMatter/utils/path/MumsMatterRoutesGenerator';
import ButtonMumsMatter from 'MumsMatter/components/ButtonMumsMatter/ButtonMumsMatter';

const Welcome = () => {
  const navigate = useNavigate();
  const { HOME } = useMumsMatterRoutesGenerator();

  return (
    <MumsMatterHelmetWrapper title={'Mums Matter Psychology | Welcome to Mums Matter Psychology'}>
      <MumsMatterContentLayout className={styles.containerWrapper}>
        <div className={styles.headerWrapper}>
          <MumsMatterHeader withPadding className={styles.header} />
        </div>
        <div className={styles.container}>
          <div className={styles.title}>Final step</div>
          <div className={styles.infoContainer}>
            <div className={styles.heading}>Welcome to the Mums Matter Psychology client portal.</div>
            <div className={styles.desc}>
              In order to get you fully registered you need to complete the following forms before your first
              appointment. If completing forms is something you struggle with, or you experience any technical
              difficulties, please email us at{' '}
              <a className={styles.link} href={`mailto:${MumsMatterSupportEmail}`} target="_blank" rel="noreferrer">
                {MumsMatterSupportEmail}
              </a>{' '}
              or call {MumsMatterSupportMobile} to discuss how we can support you. <br />
              <br />
              Please note that if forms have not been completed before your first session the beginning of the
              appointment will need to be spent completing these forms. <br />
              <br />
              For further information about Mums Matter Psychology data handling policy please{' '}
              <a className={styles.link} target={'_blank'} href={MumsMatterPrivacyPolicyUrl} rel={'noreferrer'}>
                see here
              </a>
            </div>
          </div>
          <div className={styles.taskContainer}>
            <div className={styles.heading}>Forms to complete in your account:</div>
            <div className={styles.tasks}>
              <ul>
                <li>
                  <div className={styles.circle} />
                  <div className={styles.liText}>New Client Information Form and Consent forms</div>
                </li>
              </ul>
            </div>
            <ButtonMumsMatter
              className={styles.submitButton}
              fullWidth
              type="submit"
              onClick={() => {
                navigate(HOME);
              }}
            >
              Go to your account
            </ButtonMumsMatter>
          </div>
        </div>
      </MumsMatterContentLayout>
      <TacklitFooter darkFooter />
    </MumsMatterHelmetWrapper>
  );
};

export default Welcome;
