import { PatientAddress } from 'utils/http/PatientProfileService/Patient/patient';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { validateProviderNumber } from 'utils/providerNumberValidator';

export interface ReferralDetailErrorInterface {
  referralDate: string;
  referralFirstName: string;
  referralLastName: string;
  referralProviderNumber: string;
  practiceName: string;
  files?: string;
}

export interface MumsMatterInfoErrorInterface extends PatientAddress, ReferralDetailErrorInterface {
  firstName: string;
  lastName: string;
  relationship: string;
}

export const formDataValidationSchema = (t: TFunction, isPrivateClient: boolean) => {
  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    relationship: Yup.string().required('Please select relationship'),
    line1: Yup.string().required('Please enter your address'),
    suburb: Yup.string().required(`Please enter your ${t('form.client_address_suburb').toLowerCase()}`),
    state: Yup.string().required(`Please enter your ${t('form.client_address_state').toLowerCase()}`),
    postcode: Yup.string().required('Please enter your postcode'),
    country: Yup.string().required('Please enter your country'),

    referralDate: isPrivateClient ? Yup.string().notRequired() : Yup.string().required('Please enter referral date'),
    referralFirstName: isPrivateClient
      ? Yup.string().notRequired()
      : Yup.string().required('Please enter referral first name'),
    referralLastName: isPrivateClient
      ? Yup.string().notRequired()
      : Yup.string().required('Please enter referral last name'),
    referralProviderNumber: isPrivateClient
      ? Yup.string().notRequired()
      : Yup.string()
          .required('Please enter GP provider number')
          .test('isValidProviderNumber', function (value) {
            if (!value) return true; // Required validation is already handled
            const errorMessage = validateProviderNumber(value);
            return errorMessage ? this.createError({ message: errorMessage }) : true;
          }),
    practiceName: isPrivateClient ? Yup.string().notRequired() : Yup.string().required('Please enter GP practice name'),
    files: isPrivateClient ? Yup.string().notRequired() : Yup.string().required('Please upload a referral document')
  });
};

export const validationInfoForm = (
  signUpFormVal: MumsMatterInfoErrorInterface,
  t: TFunction,
  isPrivateClient: boolean
) => {
  const validationErrors = {
    firstName: '',
    lastName: '',
    relationship: '',
    line1: '',
    suburb: '',
    state: '',
    country: '',
    postcode: '',
    referralDate: '',
    referralFirstName: '',
    referralLastName: '',
    referralProviderNumber: '',
    practiceName: '',
    files: ''
  };

  try {
    formDataValidationSchema(t, isPrivateClient).validateSync(signUpFormVal, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return {
    validationErrors,
    cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  };
};

export interface MedicareValidationErrorInterface {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  number: string;
  irn: string;
  expiryDate?: string;
  dva?: string;
  dvaCardType?: string;
  dvaCardExpiryDate?: string;
}

export const medicareFormDataValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required('Please enter first name'),
    lastName: Yup.string().required('Please enter last name'),
    dateOfBirth: Yup.string().required('Please enter date of birth'),
    number: Yup.string().matches(/^\d+$/, 'Medicare must be a number').required('Please enter medicare card number'),
    irn: Yup.string().matches(/^\d+$/, 'IRN must be a number').required('Please enter medicare IRN'),
    expiryDate: Yup.string().required('Please enter medicare expiry date'),
    dva: Yup.string().when('$isDvaChecked', {
      is: true,
      then: (schema) => schema.required('Please enter DVA file number'),
      otherwise: (schema) => schema.notRequired()
    }),
    dvaCardType: Yup.string().when('$isDvaChecked', {
      is: true,
      then: (schema) => schema.required('Please select your DVA card type'),
      otherwise: (schema) => schema.notRequired()
    }),
    dvaCardExpiryDate: Yup.string().when('$isDvaChecked', {
      is: true,
      then: (schema) => schema.required('Please select your DVA expiry date'),
      otherwise: (schema) => schema.notRequired()
    })
  });
};

export const validationMedicareForm = (
  medicareForm: MedicareValidationErrorInterface,
  isDvaChecked: boolean,
  isPrivateClient: boolean
) => {
  const validationErrors: MedicareValidationErrorInterface = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    number: '',
    irn: '',
    dva: '',
    dvaCardType: '',
    dvaCardExpiryDate: ''
  };

  if (isPrivateClient) {
    return { validationErrors: validationErrors, cleanValidationError: {} };
  }

  try {
    medicareFormDataValidationSchema().validateSync(medicareForm, {
      abortEarly: false,
      context: { isDvaChecked, isPrivateClient }
    });
  } catch (ex) {
    if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return {
    validationErrors,
    cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  };
};
