import { Form, Skeleton } from 'antd';
import DateSelector from 'components/DatePicker/DateSelector';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useState } from 'react';
import styles from './MedicareCard.module.scss';
import medicare from 'SomeoneHealth/assets/images/medicare.png';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import { DvaCardType, MedicareInformation } from 'components/UpdateProfileContent/interface';
import { useAuth0 } from '@auth0/auth0-react';
import VerificationStatus from 'components/VerificationStatus/VerificationStatus';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';

interface MedicareCardProps {
  medicareCardValue: MedicareInformation;
  setMedicareCardValue: (value: MedicareInformation) => void;
  useSignUpInfoToggle: boolean;
  setUseSignUpInfoToggle: () => void;
  showCheckToggle: boolean;
  isMedicareCardValidating: boolean;
  medicareErrorMessage: MedicareInformation['status'];
  setIsMedicareValueChanged?: (val: boolean) => void;
  className?: string;
  introClassName?: string;
  verificationStatusClassName?: string;
  callText?: string;
}

const MedicareCard = ({
  medicareCardValue,
  setMedicareCardValue,
  useSignUpInfoToggle,
  setUseSignUpInfoToggle,
  showCheckToggle,
  isMedicareCardValidating,
  medicareErrorMessage,
  setIsMedicareValueChanged,
  className,
  introClassName,
  verificationStatusClassName,
  callText = 'your'
}: MedicareCardProps) => {
  const { isLoading } = useAuth0();
  const [displayDVAField, setDisplayDVAField] = useState(!!medicareCardValue.dva);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, className)}>
        <div className={styles.leftContent}>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Form>
              {showCheckToggle && (
                <div className={styles.fieldContainer}>
                  <CheckBox
                    inputClassName={styles.nameIsRegisteredClass}
                    id={'nameIsRegistered'}
                    value={useSignUpInfoToggle}
                    onChange={setUseSignUpInfoToggle}
                    label={'My name registered with medicare matches my Profile first name and last name.'}
                  />
                </div>
              )}
              {!useSignUpInfoToggle && (
                <>
                  <div className={styles.fieldContainer}>
                    <MaterialInput
                      label={`${callText} Medicare First Name`}
                      id={'firstNameMedicare'}
                      onChange={(e) => {
                        setMedicareCardValue({ ...medicareCardValue, firstName: e.target.value });
                        setIsMedicareValueChanged?.(true);
                      }}
                      value={medicareCardValue.firstName}
                      required
                    />
                    <div className={styles.fieldDescription}>
                      Please include {callText} first name registered with medicare
                    </div>
                  </div>
                  <div className={styles.fieldContainer}>
                    <MaterialInput
                      label={`${callText} Medicare Last Name`}
                      id={'lastNameMedicare'}
                      onChange={(e) => {
                        setMedicareCardValue({ ...medicareCardValue, lastName: e.target.value });
                        setIsMedicareValueChanged?.(true);
                      }}
                      value={medicareCardValue.lastName}
                      required
                    />
                    <div className={styles.fieldDescription}>
                      Please include {callText} last name registered with medicare
                    </div>
                  </div>
                  <div className={classNames(styles.fieldContainer, styles.date)}>
                    <DateSelector
                      format={'DD/MM/YYYY'}
                      label={`${callText} Date of Birth`}
                      name="dobMedicare"
                      id="dobMedicare"
                      value={medicareCardValue.dateOfBirth || ''}
                      placeholder="DD/MM/YYYY"
                      inputReadOnly={false}
                      onChange={(value: any) => {
                        setMedicareCardValue({ ...medicareCardValue, dateOfBirth: value });
                        setIsMedicareValueChanged?.(true);
                      }}
                    />
                    <div className={styles.fieldDescription}>
                      Please include {callText} date of birth registered with medicare
                    </div>
                  </div>
                </>
              )}
              <div className={styles.fieldContainer}>
                <MaterialInput
                  label={`${callText} Medicare Card Number`}
                  id={'cardNumber'}
                  onChange={(e) => {
                    setMedicareCardValue({ ...medicareCardValue, number: parseInt(e.target.value, 10) });
                    setIsMedicareValueChanged?.(true);
                  }}
                  value={medicareCardValue.number}
                  required
                  type={'number'}
                />
                <div className={styles.fieldDescription}>Please include all numbers with no spaces</div>
              </div>
              <div className={styles.fieldContainer}>
                <MaterialInput
                  label={`${callText} Individual Reference Number (IRN)`}
                  id={'irn'}
                  onChange={(e) => {
                    setMedicareCardValue({ ...medicareCardValue, irn: parseInt(e.target.value, 10) });
                    setIsMedicareValueChanged?.(true);
                  }}
                  value={medicareCardValue.irn}
                  required
                  type={'number'}
                />
                <div className={styles.fieldDescription}>
                  This is the number indicating {callText} position on the card
                </div>
              </div>
              <div className={classNames(styles.fieldContainer, styles.date)}>
                <DateSelector
                  picker={'month'}
                  format={'MM/YYYY'}
                  label={'The expiry date'}
                  name="expiryDate"
                  id="expiryDate"
                  value={medicareCardValue.expiryDate || ''}
                  placeholder="MM/YYYY"
                  onChange={(value: any) => {
                    setMedicareCardValue({ ...medicareCardValue, expiryDate: value });
                    setIsMedicareValueChanged?.(true);
                  }}
                />
                <div className={styles.fieldDescription}>MM/YYYY</div>
              </div>

              <div className={styles.fieldContainer}>
                <CheckBox
                  inputClassName={styles.nameIsRegisteredClass}
                  id={'dva-checkbox'}
                  value={displayDVAField}
                  onChange={() => {
                    if (displayDVAField) {
                      setMedicareCardValue({
                        ...medicareCardValue,
                        dva: undefined,
                        dvaCardDetails: undefined
                      });
                      setIsMedicareValueChanged?.(true);
                    }
                    setDisplayDVAField(!displayDVAField);
                  }}
                  label={"I have a Department of Veterans' Affairs (DVA) card"}
                />
              </div>

              {displayDVAField && (
                <>
                  <div className={styles.fieldContainer}>
                    <MaterialInput
                      label={'DVA File Number'}
                      id={'dva'}
                      onChange={(e) => {
                        setMedicareCardValue({
                          ...medicareCardValue,
                          dva: e.target.value
                        });
                        setIsMedicareValueChanged?.(true);
                      }}
                      value={medicareCardValue.dva}
                    />
                    <div className={styles.fieldDescription}>This is the file number in {callText} DVA card</div>
                  </div>

                  <div className={classNames(styles.fieldContainer, styles.selectDropdown)}>
                    <MaterialSelect
                      label={'DVA Card Type'}
                      id={'dvaCardType'}
                      isSearchable={false}
                      optionList={Object.values(DvaCardType).map((val) => ({
                        label: val.toUpperCase(),
                        value: val
                      }))}
                      value={medicareCardValue.dvaCardDetails?.type || ''}
                      onChange={(val: DvaCardType) => {
                        setMedicareCardValue({
                          ...medicareCardValue,
                          dvaCardDetails: {
                            ...medicareCardValue.dvaCardDetails,
                            type: val
                          }
                        });
                        setIsMedicareValueChanged?.(true);
                      }}
                    />
                    <div className={styles.fieldDescription}>This is the type of {callText} DVA card</div>
                  </div>

                  <div className={classNames(styles.fieldContainer, styles.date)}>
                    <DateSelector
                      picker={'month'}
                      format={'MM/YYYY'}
                      label={'DVA expiry date'}
                      name="dvaExpiryDate"
                      id="dvaExpiryDate"
                      value={medicareCardValue.dvaCardDetails?.expiryDate || ''}
                      placeholder="MM/YYYY"
                      onChange={(value: string) => {
                        setMedicareCardValue({
                          ...medicareCardValue,
                          dvaCardDetails: {
                            ...medicareCardValue.dvaCardDetails,
                            expiryDate: value
                          }
                        });
                        setIsMedicareValueChanged?.(true);
                      }}
                    />
                    <div className={styles.fieldDescription}>MM/YYYY</div>
                  </div>
                </>
              )}

              <div className={styles.checkNumberStatus}>
                <VerificationStatus
                  status={medicareErrorMessage}
                  isLoading={isMedicareCardValidating}
                  hideDesc
                  className={verificationStatusClassName}
                />
              </div>
            </Form>
          )}
        </div>
        <div className={styles.rightContent}>
          <div className={classNames(styles.medicareIntro, introClassName)}>
            <div className={styles.title}>
              <i className={classNames(styles.infoIcon, `material-icons`)}>info</i>
              <div>Please enter the detail from {callText} medicare card as outlined in the image below:</div>
            </div>
            <img className={styles.medicareImg} src={medicare} alt={'Medicare card Intro'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicareCard;
