import styles from './HomeNoTaskBox.module.scss';
import { CLIENT_BRAND_LABEL, CLIENT_BRAND } from 'interfaces/ClientBrand';
import SuggestionCard from './SuggestionCard/SuggestionCard';
import classNames from 'classnames';

export interface noTaskBoxContentInterface {
  title: string;
  desc: string;
  image: string;
  button?: {
    label: string;
    link: string;
    hideButton?: boolean;
  };
}

interface HomeNoTaskBoxProps {
  taskContent: noTaskBoxContentInterface[];
  noTaskMessage?: JSX.Element;
  className?: string;
}

const HomeNoTaskBox = ({ className, taskContent, noTaskMessage }: HomeNoTaskBoxProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {noTaskMessage ? (
        <>{noTaskMessage}</>
      ) : (
        <>
          <div className={styles.label}>
            Here in your <b>{CLIENT_BRAND_LABEL[CLIENT_BRAND]}</b> account you can manage all aspects of your care
            program.
            <br />
            <br />
            You will see any tasks assigned to you, and can manage your appointments and billing in one secure place.
          </div>
          <div className={styles.taskLabel}>
            You currently have <span className={styles.number}>0</span> outstanding tasks
          </div>
        </>
      )}
      <div className={styles.suggestionWrapper}>
        {taskContent.map((taskObj, index) => (
          <SuggestionCard
            key={index}
            title={taskObj.title}
            desc={taskObj.desc}
            image={taskObj.image}
            button={!taskObj.button?.hideButton ? taskObj.button : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeNoTaskBox;
