import CheckBox from 'components/CheckBox/CheckBox';
import Icon from 'components/Icon/Icon';

import medicareLogo from 'assets/images/medicareBadge.png';

import styles from './PaymentPolicy.module.scss';
import { IntakeAppointmentNoticePeriodInHour, IntakeCancellationFee } from 'MumsMatter/utils/env/MumsMatterEnv';

const MENU_ITEMS = [
  {
    icon: 'alarm',
    content: `Appointment changes are free of charge as long as you provide us ${IntakeAppointmentNoticePeriodInHour} hours notice.`
  },
  {
    icon: 'today',
    content: `Appointment changes within ${IntakeAppointmentNoticePeriodInHour} hours or a ‘no show’ will be charged a $${IntakeCancellationFee} fee.`
  },
  {
    icon: 'monetization_on',
    content:
      'Appointments that are bulk billed require no payment. For appointments incurring a fee, the full payment will be processed two days prior to your scheduled appointment. If you are eligible for a Medicare rebate and we have all required paperwork on file, your rebate will be processed within 2 business days after your appointment.'
  }
];

interface PaymentPolicyProps {
  policyAccepted: boolean;
  onSetPolicyAccepted: (value: boolean) => void;
  processingPolicyAccepted: boolean;
  onSetProcessingPolicyAccepted: (value: boolean) => void;
}

const PaymentPolicy = ({
  policyAccepted,
  onSetPolicyAccepted,
  processingPolicyAccepted,
  onSetProcessingPolicyAccepted
}: PaymentPolicyProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.title}>Cancellation & Charging Policy</div>
        {MENU_ITEMS.map((item, index) => (
          <div key={index} className={styles.wrapper}>
            <Icon className={styles.icon} name={item.icon} />
            <span className={styles.desc}>{item.content}</span>
          </div>
        ))}
        <CheckBox
          id="policyAccepted"
          value={policyAccepted}
          onChange={(event) => onSetPolicyAccepted(event.target.checked)}
          label="I have read, understood and agree to the Mums Matter Cancellation & Charging Policy"
        />
      </div>
      <div className={styles.subContainer}>
        <div className={styles.processingPolicyTitle}>
          <img src={medicareLogo} alt={'medicare'} width={120} height={36} />
          <div className={styles.title}>Processing Policy</div>
        </div>
        <span className={styles.desc}>
          If your appointment is eligible for a Medicare Rebate we will process that on your behalf, with any benefit
          payable returning to your bank account on file with Medicare. We process most claims within 4 days post your
          appointment date, but cannot guarantee the Medicare rebate or timing of any payment.
        </span>
        <CheckBox
          id="processingPolicyAccepted"
          value={processingPolicyAccepted}
          onChange={(event) => onSetProcessingPolicyAccepted(event.target.checked)}
          label="I have read, understood the Medicare Processing Policy"
        />
      </div>
    </div>
  );
};

export default PaymentPolicy;
