import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import classNames from 'classnames';
import MumsMatterHelmetWrapper from '../../components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterHeader from '../../components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from '../../components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';

const UpdateProfile = () => {
  return (
    <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Profile`}>
      <MumsMatterHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <MumsMatterContentLayout className="mums-matter-theme">
          <UpdateProfileContent contentClassName={classNames(styles.content, 'mums-matter-theme')} />
        </MumsMatterContentLayout>
      </div>
    </MumsMatterHelmetWrapper>
  );
};

export default UpdateProfile;
