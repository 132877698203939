import styles from './IntakeBookingPage.module.scss';
import IntakeBooking from './components/IntakeBooking/IntakeBooking';
import MumsMatterHelmetWrapper from 'MumsMatter/components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';
import MumsMatterContentLayout from 'MumsMatter/components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHeader from 'MumsMatter/components/Layout/MumsMatterHeader/MumsMatterHeader';
import classNames from 'classnames';

const IntakeBookingPage = () => (
  <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Intake Call`}>
    <MumsMatterHeader withPadding withMenu homeLink />
    <div className={styles.container}>
      <MumsMatterContentLayout className={classNames(styles.contentLayoutWrapper, 'mums-matter-theme')}>
        <div className={styles.welcomeMessage}>Intake Call Appointment</div>
        <IntakeBooking />
      </MumsMatterContentLayout>
    </div>
  </MumsMatterHelmetWrapper>
);

export default IntakeBookingPage;
