import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import MumsMatterRoutes from './MumsMatterRoutes';
import appRoute from '../../components/AppRoute/AppRoute';
import MumsMatterLoginWrapper from 'MumsMatter/components/Layout/MumsMatterLoginWrapper/MumsMatterLoginWrapper';

const MumsMatterRoutesCollection = () => (
  <Routes>
    {appRoute({ path: '/facilitated/*', component: FacilitatedRoutes, layout: MumsMatterLoginWrapper })}
    <Route path={'/*'} element={<MumsMatterRoutes />} />
  </Routes>
);

export default MumsMatterRoutesCollection;
