import momentTz from 'moment-timezone';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';

import { MOMENTJS_FORMAT_DATE } from 'components/AvailableTimeSelect/constants';

export const defaultClinicianTimeZone = 'Australia/Melbourne';

export const massageTimeSlotReverse = (
  timeSlot: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.startTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();
  const clientEndTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.endTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();

  return {
    ...timeSlot,
    date: clientStartTimeZone.format(MOMENTJS_FORMAT_DATE),
    endDate: clientEndTimeZone.format(MOMENTJS_FORMAT_DATE),
    startTime: clientStartTimeZone.format('HH:mm'),
    endTime: clientEndTimeZone.format('HH:mm'),
    startDateTime: clientStartTimeZone.toDate(),
    endDateTime: clientEndTimeZone.toDate()
  };
};
