import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import MumsMatterHeader from '../../components/Layout/MumsMatterHeader/MumsMatterHeader';
import MumsMatterContentLayout from '../../components/Layout/MumsMatterContentLayout/MumsMatterContentLayout';
import MumsMatterHelmetWrapper from '../../components/Layout/MumsMatterHelmetWrapper/MumsMatterHelmetWrapper';

const ConsentListWrapper = () => (
  <MumsMatterHelmetWrapper title={`Mums Matter Psychology | Consent List`}>
    <MumsMatterHeader withPadding withMenu homeLink />
    <div className={styles.container}>
      <MumsMatterContentLayout className="mums-matter-theme">
        <ConsentList />
      </MumsMatterContentLayout>
    </div>
  </MumsMatterHelmetWrapper>
);

export default ConsentListWrapper;
